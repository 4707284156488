@media (min-width: 600px) {
    .statsPage{
        background:#f7f7f7;
    }
    .statsPageContainer{
        padding:2%;
    }
    .bodyGridStats{
        min-height:45vh;
        width: 66vw;
        display: grid;
        grid-template-columns: 17% auto 17%;
        padding: 1% 5%;
    }
    .statsPageContainer button{
        min-width: fit-content;
        min-height: 40px;
        cursor:pointer;
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .statsPageContainer button:hover{
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    }
    .clicked{
        background: var(--blueAVR) !important;
        color:white;
    }
    .statsPageContainer input{
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .selectedBlue{
        background: var(--blueAVR) !important;
        color:white;
    }
    .selectedGreen{
        background: rgb(8, 167, 8) !important;
        color:white;
    }
    .selectedRed{
        background: red !important;
        color:white;
    }
    .selectedOrange{
        background: rgb(255, 208, 0) !important;
    }
    .leftButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .rightButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bottomButtonsStats{
        text-align: center;
    }
    .chartContainer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .statsButtons{
        display: inline;
        padding: 0.5% 2%;
        margin: 0 2%;
    }
    .statsButtonContainer{
        margin-top:3%;
        margin-left:6%;
    }
    .testoTempistiche{
        display:inline-block;
        margin:0 5%;
    }
    .containerTempistiche{
        margin:1%;
        overflow-y: auto;
        min-height: 58vh;
    }
    .headContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
    }
    .bodyContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
        font-weight: 300;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        padding: 1%;
        background-color: white;
        margin: 1%;
    }
    .containerTempistiche span{
        text-align: center;
    }
    .oreRisposta{
        font-weight: 600;
        /* color:var(--blueAVR); */
    }
    .avvisoChart{
        position: absolute;
        color: red;
    }
    .headStatsPage{
        margin:0 5%;
        font-weight: 300;
    }
    .selectTempistiche{
        margin: 0 0 0 2vw;
    }
}

@media (max-width: 600px) {
    .statsPage{
        background:#f7f7f7;
    }
    .statsPageContainer{
        padding: 5vw;
        width: 90vw;
    }
    .bodyGridStats{
        padding: 2% 5%;
    }
    .statsButtonContainer{
        margin-top: 17vh;
        width: 90vw;
        display: flex;
    justify-content: space-around;
    }
    .statsPageContainer button{
        min-height: 40px;
        cursor:pointer;
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .statsPageContainer button:hover{
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    }
    .statsPageContainer input{
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .statsButtons{
        display: inline;
        padding: 0.5% 2%;
        margin: 0 2%;
    }
    .selectedBlue{
        background: var(--blueAVR) !important;
        color:white;
    }
    .selectedGreen{
        background: rgb(8, 167, 8) !important;
        color:white;
    }
    .selectedRed{
        background: red !important;
        color:white;
    }
    .selectedOrange{
        background: rgb(255, 208, 0) !important;
    }
    .leftButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .rightButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bottomButtonsStats{
        text-align: center;
        margin-top: 2vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .chartContainer{
        width:80vw;
        height:75vh;
    }


    .clicked{
        background: var(--blueAVR) !important;
        color:white;
    }
    .testoTempistiche{
        display:inline-block;
        margin-right: 2vw;
    }
    .containerTempistiche{
        margin:1%;
        /* overflow-y: auto; */
    }

    .headContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
        font-weight: 600;
        overflow-wrap: break-word;
    }
    .bodyContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
        font-weight: 300;
        border-radius: 2px;
        padding: 1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .containerTempistiche span{
        text-align: center;
    }
    .oreRisposta{
        font-weight: 600;
        color:var(--blueAVR);
    }
    .selectTempistiche{
        width: 54vw;
        margin: 6% 3%;
    }
    .oggettoConversazioneTempistiche{
        overflow-wrap: break-word;

    }
    .avvisoChart{
        /* position: absolute; */
        color: red;
    }
}