.utentiUfficioLista{
    opacity:0;
    padding:0;
    display: none;
    height:0;
    //transition: opacity 0.1s;

}
.utentiUfficioListaAperta{
    padding:1%;
    opacity:1;
    height:100%;
    display: block;
    transition: opacity 0.6s;
}

.utentiUfficioRiga{
    display:grid;
    grid-template-columns: 25% 35% auto ;
    font-weight:200;
    padding:0.5% 0;

}
.utentiUfficioButtons{
    cursor:pointer;
}
.utentiUfficioButtons:hover{
    font-weight: 500;
}

.mostraUtentiButton{

    min-width: fit-content;
    cursor: pointer;
    margin: 2%;
    border: none;
    border-radius: 5px;
    padding: 1% 2%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    display: inline-block;
    color: var(--blueAVR);

}
.mostraUtentiButton:hover{
    font-weight: 600;
}

@media (min-width: 600px) {
    .singleUser{
        display: grid;
        grid-template-columns: auto 20% 20% 20%;
        padding: 1%;
        padding-left: 2%;
        font-weight: 300;
        margin-top: 1.5vh;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: white;
    }

    .usersButtons{
        cursor:pointer;
        color:var(--blueAVR);
        font-weight: 400;
    }
    .usersButtons:hover{
        text-decoration: underline;
        font-weight: 500;

    }

    .containerGestioneUtenti{
        padding: 0% 2%;
        height: 63vh;
        margin: 0 8%;
        width: 73%;
        padding-top:4vh
    }
    .innerDivContainer{
        overflow-y: auto;
        height: 65vh;
        margin-top: 2vh;
        padding-bottom: 1vh;
    }
    .headGestioneUtenti{
        font-weight: 300;
    }
}


@media (max-width: 600px) {

    .containerGestioneUtenti{
        margin-top: 14vh;
        width: 100vw;
    }
    .headGestioneUtenti{
        padding: 7%;
    }
    .innerDivContainer{
        padding: 4%;
    }
    .singleUser{
        display: grid;
        grid-template-columns: auto 30% 26%;;
        box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 20%);
        border-radius: 2px;
        min-height: 5vh;
        margin-bottom: 2vh;
        padding: 3% 3%;
    }
    .usersButtons{
        /* cursor:pointer; */
        color:var(--blueAVR);
        font-weight: 600;
    }

    .utentiUfficioButtons{
        cursor:pointer;
        color:var(--blueAVR);
        padding:2%;
    }
}