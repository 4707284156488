@media (max-width: 600px) {
.storeMessageButton {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.archiviaButton{
    padding:1% 2%;
}
}