.buttonsContMsg{
    text-align: center;
}

#divTopicNot{ 
    display: grid;
    grid-template-columns: 50% 50%;
    /* text-align: center; */
}

#delNotMod{
    width: 9vw;
    height: 5.5vh;
    margin-top: 4%;
    margin-left: 30%;
}

.textContainer{
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-top: 4vh;
    overflow-y:auto;
    min-height: 10vh;
    max-height:24vh;
    text-align: justify;
    padding:2%;
    font-weight: 200;
}

.headModalColumns{
    display:grid;
    grid-template-columns: 65% 40%;
    margin-bottom:3vh;
    grid-row-gap: 1vh;
}

.modalOpenMessage{
    position: absolute;
    inset: 50% auto auto 50%;
    border: 2px solid rgb(0, 119, 188);
    background: rgb(255, 255, 255);
    border-radius: 0px;
    outline: none;
    padding: 3%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    min-width: 40vw;
    max-width: 50vw;
    max-height: 50vh;
}

.modalOpenMessageReply{
    position: absolute;
    inset: 50% auto auto 30%;
    /* border: 2px solid rgb(0, 119, 188); */
    background: rgb(255, 255, 255);
    /* border-radius: 0px; */
    outline: none;
    padding: 1%;
    /* margin-right: -50%; */
    /* transform: translate(-50%, -50%); */
    box-shadow: rgb(8 37 56) 2px 2px 7px;
    min-width: 52vw;
    max-width: 50vw;
    max-height: 24vh;
}

.replyMessageCont{
    text-align:center;
    padding-top: 3vh;
}
.replyMessageCont textarea{
    border: none;
    border-radius: 10px;
    min-width: 30vw;
    min-height: 10vh;
    background-color: rgb(241, 241, 241);
    padding: 2%;
    font-family: inherit;
    caret-color: var(--blueAVR);
    margin-top:3vh;
    text-align: justify;
}
#clickedNoticeTitle{
    margin: 0 0 0 1vw;
    font-weight: 500;
}


@media (max-width: 600px) {
    #delNotMod{
        position: absolute;
        bottom: 5vh;
        left: 35vw;
        width: 32vw;
        font-weight: 400;
        max-height: 6vh;
        margin: 0 auto;
        font-size: small;
    }
    .textContainer{
        max-height: inherit;
        padding: 4% 4%;
        margin-top: 4vh;
        font-size: medium;
        font-weight: 200;
            
    }
    #clickedNoticeTitle{
        font-weight: 500;
        text-align: justify;
        max-width: 72vw;
        margin: 5vh auto 2vh 7vw;
    }
    #divTopicNot {
        display:block;
        text-align: center;
    }
    #contenutoSingleNotice{
        margin:9% 0 ;
    }
    .modalOpenMessage{
        font-size: smaller;
        inset: unset;
        transform:unset;
        border:none;
        border-radius: 0;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        padding: 5%;
        min-width: none;
        min-height: none;
        max-height: none;
        max-width: none;
        margin: 0 auto;
    }

    .modalOpenMessageReply{
        font-size: smaller;
        inset: unset ;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        min-width: none;
        min-height: none;
        max-height: none;
        max-width: none;
        margin: 0 auto;
        padding: 4%;
        font-weight: 200;
        transform:unset;
        border:none;
        border-radius: 0;
    }
    .replyMessageCont textarea{    
        min-width: 69vw;
        min-height: 36vh;
        margin-top: 8%;
    }
    .replyMessageCont h4{    
        margin-top: 11%;
        font-weight: 400;
    }
    .headModalColumns{
        display:block;
    }
    .headModalColumns div{
        margin-top:7%
    }
    .buttonsContMsg{
        position: absolute;
        bottom: 4vh;
        left: 0;
        width: -webkit-fill-available;
    }
}