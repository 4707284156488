@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  /* --blueAVR: #0077bc; */
  --blueAVR: #005151;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.2;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

b{font-weight: 500;}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); 
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3); 
}

textarea,
input:matches(
  [type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]
) {
  -webkit-appearance: none;
  background-image: linear-gradient(transparent, transparent);
  border-radius: 0;
}

/* LOADER */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-left: 45%;
  color:var(--blueAVR);
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--blueAVR);
  border-color: var(--blueAVR) transparent var(--blueAVR) transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pMenu{
  width: 300px;
  white-space: normal !important;
}


.loginPage {
    text-align: center;
}
label {
    display: block;
}
.inputLogin {
    border: none;
    border-bottom: solid 1px var(--blueAVR);
    caret-color: var(--blueAVR);
    padding-left: 1%;
    margin-bottom: 2vh;
}
input:focus{
    outline: none;
}
.buttonSignin {
    cursor: pointer;
    padding: 1vh 2vw;
    font-size: large;
    font-family: inherit;
    font-weight: 400;
    background: var(--blueAVR);
    color: white;
    background: var(--blueAVR);
    border: solid 3px var(--blueAVR);
    border-radius: 25px;
}
.buttonSignin:active{
    color:var(--blueAVR);
    background: #f7f7f7;
    border: solid 3px  #f7f7f7;
}
.clickHere {
    display: inline;
    color: var(--blueAVR);
    cursor: pointer;
}
.clickHere:hover {
    font-weight: bold;
}

#errorMessageLogin{
    color:red;
    margin:2%
}
#infoModalOk{
    padding: 1% 3%;
}
#usernameLabel{
    display:inline-block;
    margin:0 auto;
    margin-left:2%;
    color: var(--blueAVR);
    font-weight: 600;
}
.showPasswordIcon{
    /* min-width:13px; */
    width: 15px !important;
    cursor:pointer;
    color: var(--blueAVR);
}

#registerButton{
   /*  margin:0 0 5vh 0 !important; */
   margin-bottom:2%;
}
.insertImages{
    /* margin-top:2%; */
}

.infoModalRegistration{
    position: absolute;
    inset: 51% auto auto 50%;
    background: rgb(255, 255, 255);
    outline: none;
    padding: 3%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
   /*  max-width: 30vw; */
   max-width: 50vw;
    min-height: 20vh;
    text-align: center;
    font-weight: 300;
}
.textAreaAnonyme{
    border: none;
    border-radius: 2px;
    min-width: 29vw;
    height: 8vh;
    padding: 2%;
    font-family: inherit;
    caret-color: var(--blueAVR);
    text-align: justify;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}




.sceltaUfficioDiv{
    display:flex; 
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.sceltaUfficioDiv select{
    margin:5vh 0;
}
    
#accediSAMLButton{
    font-size: small;
    padding: 0.5rem;
    border-radius: 20px;
    margin-top:1.5rem;
}

@media (min-width:600px) {

    #bodyLoginRight{
        height: 100vh;
        width: 66vw;
        position: absolute;
        transition: all 1s ease-out;
        text-align: center;
    }
    .left2 {
        left: 0;
    }
    .right2{
        left: 34vw;
    }
    #bodyRegisterLeft{
        text-align: center;
        padding: 14vh 7vw;
    }
    .errorMessage{
        color:red;
        font-size: medium;
        margin: 1%;
    }
    .registerButtons{
        padding: 1.5% 2% !important;
    }
    .regexpDescrP{
        padding: 0 11vw;
        margin:2%;
    }

    #bodyLoginLeft {
        background: var(--blueAVR);
        width: 34vw;
        height: 100vh;
        position: absolute;
        transition: all 1s ease-out;
        z-index:1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .left {
        left: 0;

        display: flex;
        flex-direction: column;
        justify-items: center;
    }
    .left img{
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
        transition: all 0.5s ease-in;
    }

    .left img.logoAvr{
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
        transition: all 0.5s ease-in;

        width: 100px;
    }
    
    .right{
        left: 66vw;
        display: flex;
        flex-direction: column;
        justify-items: center;
    }

    .right img{
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
        transition: all 0.5s ease-in;
    }
    .right img.logoAvr{
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
        transition: all 0.5s ease-in;

        width: 100px;
    }

    #logoPrincipaleLogin {
        /* position: absolute;
        top: 22vh;
        left: 6vw; */
        max-width: 77%;
    }
    .formInput {
        background: white;
        padding-top: 15vh;
        /* margin-left: 15vw; */
    }
    .inputLogin {
        font-size:inherit;
    }
    main{
        height:100vh;
    }

    .imgMobile{
        display: none;
    }

    .titoloLogin {
        display: block;
    }

    .titoloRegister {
        display: block;
    }
 
    .privacyLegalHomePage a{
        text-decoration: none;
        color: var(--blueAVR);
        position:absolute;
        bottom:2vh;
        font-size: 0.8em;
    }
}

@media (max-width:600px) {
    #bodyLoginRight{
        text-align: center;
    }
    .infoModalTitle{
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-size: x-large;
    }
    .errorMessage{
        color:red;
        font-size:small;
        width: 100vw;
    }
    .regexpDescrP{
        padding: 3vw;
        /* margin-top: 7vh; */
        font-size: small;

    }
    #bodyRegisterLeft{
        /* font-size: smaller; */
        text-align: center;
        padding-top: 10vh;
    }

    #logoPrincipaleLogin {
        display: none;
    }
    .formInput {
        background: white;
        padding-top: 10vh;
        width: 100vw;
        /* font-size:small; */
    }
    .titoloLogin {
        text-align: center;
        width: 100vw;
        text-decoration: none;
    }
    #accediButton {
        margin-top: 5vh;
        width: 40vw;
        font-weight: initial;
    }
    .buttonSignin {
        padding: 1vh 3vw;
    }
    .infoModalRegistration{
        border: none;
        border-radius: 0;
        font-size: small;
        inset: unset;
        /* width: -webkit-fill-available;  */
        height:100vh;
        max-height: none;
        max-width: none;
        margin: 0 auto;
        -webkit-transform: unset;
                transform: unset;
        padding: 5% 7%;
    }
    .first{
        font-size: medium;
       /*  width: 76vw; */
        padding-top: 10vh;
    }

    .first h2{
        margin-bottom: 10vh;
    }

    .infoModalRegistration button {
        font-size: medium;
        margin: 1vh;
        padding: 1.5% 2%;
    }
    #registerButton {
        margin: 0 0 3vh 0 !important;
        padding: 1vh 4vw;
    }
    #infoModalOk{
        min-width: 32vw;
        margin-top: 5vh;
    }
    .showPasswordIcon{
        right: 25% !important;
    }
    .clickHere {
        font-size: smaller;
    }
    .headAnonyme{
        padding: 0 5%;    
        font-weight: 300;
    }
    .textAreaAnonyme{
        min-width: 75vw;
        height: 15vh;
    }
    .selectModello231{
        width:55vw;
    }
    .imgMobile{
        display: block;
    }

    .imgMobile img{
        width: 10em;
        margin: 0 0 3em;
    }

    .titoloLogin {
        display: none;
    }

    .titoloRegister {
        display: none;
    }

    .sceltaUfficioDiv{
        margin: 0 7vw ;
    }
    .sceltaUfficioDiv select{
        width:50vw;
    }
    .privacyLegalHomePage {
        margin:5%;
    }
    .privacyLegalHomePage a{
        text-decoration: none;
        color: var(--blueAVR);
        font-size: 1em;
    }

}



.rowsHeadRight {
    display: grid;
    grid-template-rows: 50% 50%;
    justify-content: center;
}

.columnsHead{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom:2%;
}

h2{
    margin-right: 0%;
    color : var(--blueAVR);
    font-weight: 400;
}

.headNewNot h2{
    font-size: x-large;
}

button{
    margin: 1%;
} 


.infoModalTitle{
    margin: 2% 0;
    font-size: x-large;
}
#orderButtonsContainer{
    padding: 2vh;
}

#dataButton{
    width: 10vw;
}
#rateButton{
    width: 10vw;
}
#ordinaPer{
    display: inline;
    font-weight: 300;
}
#ricevutiButton{
    padding: 1% 3%;
}

#inviatiButton{
    padding: 1% 3%;
}
#archiviatiButton{
    padding: 1% 3%;
}
@media (min-width: 600px) {
    .titoloLogin{
        font-size: xx-large;
        margin-bottom: 3%;
    }
    .titoloRegister{
        font-size: xx-large;
        margin-bottom: 3%;

    }
    .headBacheca{
            margin: 4vh 0 2vh 3vw;
            grid-column: 1/3;
    }
    .headMessage{
        margin: 7vh 0 5vh 0;
    }

    .bachecaAvvisi{
        display:none;
    }

    .messagesTitle{        
        display:none;
    }
}
@media (max-width: 600px) {
    .columnsHead{
        display: block;
        grid-template-columns: 0% 0%;
        margin: 0 auto;
    }
    .bachecaAvvisi{
        width: 100vw;
        font-size: large;
        margin-top: 16vh;
        text-align: center;
    }
    #ordinaPer{
        display: block;
        font-size: small;
        font-weight: 200;
        width: 100vw;
        text-align: center;
        margin: 3vh auto 1vh auto;
    }
    #orderButtonsContainer{
        display: flex;
        width: 100vw;
        justify-content: space-around;
        padding:0;
    }
    #dataButton{
        width: 37vw;
        /* max-height: 6vh; */
        font-weight: 600;
        padding: 2% 2%;
    }
    
    #rateButton{
        width: 40vw;
        font-weight: 600;
        padding: 2% 2%;
    }

    #archiviatiButton{
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 5%;
    }

    .messagesTitle{
        font-weight: 400;
        padding-top: 2vh;
        margin-bottom: 8vh;
        text-align: center;
        width: 100vw;
        font-size: large;
        text-decoration: none;
    }

    #ricevutiButton{
        width: 34vw;
        font-weight: 600;
        padding: 2% 3%;
    }
    
    #inviatiButton{
        width: 30vw;
        font-weight: 600;
        padding: 2% 3%;
    }

    #containerRicevutiInviati{
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100vw;
        justify-items: center;
    }

   
}
.singleNotice{
    cursor:pointer;
    display:grid;
    grid-template-columns: auto 25% 20%;
    padding-left: 2%;
    font-weight: 300;
    margin-bottom: 2.5vh;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background:white;
}

.singleNotice:hover{
    color:var(--blueAVR);
    /* font-weight:bold; */
    border-radius: 9px;
    border-radius: 2px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.dataNotice{
    padding: 1.5em;
    text-align: center;
    font-size: .8em;
}

.ratingNotice{
    padding: 1.5em;
    text-align: center;
    font-size: .8em;
}

.headListaNotices{
    display:grid;
    grid-template-columns: auto 15% 28%;
    font-weight:500;
    margin-left:2%;
    text-align: center;
}

#starIcon{
    cursor: help;
}
.tooltipStarsRating{
    background-color: rgb(245, 245, 245);
    padding: 0.7%;
    border-radius: 5px;
    position: absolute;
    width: 12vw;
    text-align: center;
    left: 52%;
    top: 28vh;
    opacity:0;
    font-weight:400;
    font-size: small;
}

#starIcon:hover + .tooltipStarsRating{
    opacity:1;
}

#clockIcon{
    margin-left:11vw;
    cursor: help;
}
#clockIcon:hover + .tooltipClock{
    opacity:1;
}

.tooltipClock{
    background-color: rgb(245, 245, 245);
    padding: 0.5%;
    border-radius: 5px;
    position: absolute;
    width: 9vw;
    text-align: center;
    left: 71vw;
    top: 24vh;
    opacity:0;
    font-weight:400;
    font-size: small;
}

#topicIcon{
    cursor: help;
}

#topicIcon:hover + .tooltipTopic{
    opacity: 1;
}

.tooltipTopic{
    background-color: rgb(245, 245, 245);
    padding: 0.5%;
    border-radius: 5px;
    position: absolute;
    width: 13vw;
    text-align: center;
    left: 29%;
    top: 30vh;
    opacity:0;
    font-size: small;
    font-weight:400;
}

.modalOpenNotice{
    position: absolute;
    inset: 50% auto auto 50%;
    /* border: 2px solid rgb(0, 119, 188); */
    background: rgb(255, 255, 255);
    border-radius: 0px;
    outline: none;
    padding: 2%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    min-width: 40vw;
    max-width: 50vw;
    max-height: 50vh;
}

.modalConfirmationDelete{
    position: absolute;
    inset: 50% auto auto 50% !important;
    /* border: 2px solid rgb(0, 119, 188) !important; */
    background: rgb(255, 255, 255);
    border-radius: 0px !important;
    outline: none;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    min-width: 30vw;
    text-align: center;
    padding: 2%;
}

.confermaDelete{
    margin-top:5%;
}
.iconeCardNotices{
    display:none;
}
.topicNotice{
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 37vw;
    padding: 4% 0 2%;
}
.textNotice{
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 37vw;
    font-size: smaller;
    padding: 2% 0;
}

@media (max-width: 600px) {
    .listaNot{
        margin-top: 0vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        font-size: small;
        width: 94vw;
        padding: 0 3vw;
        padding-bottom: 10vw;
    }
    .tooltipTopic{
        display:none;
    }
    .textNotice{
        display:none;
    }
    .tooltipStarsRating{
        display:none;
    }
    .tooltipClock{
        display:none;
    }
    .headListaNotices{
        display: none;
    }

    .singleNotice{
    display: block;
    box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 20%);
    border-radius: 2px;
    min-height: 11vh;
    margin-bottom: 2vh;
    }
    
    .singleNotice:hover{
        border-radius: 0px;
    }
    #clockIcon{
        margin-left: 7vw;
        margin-top: 0.5vh; 
    }
    .iconeCardNotices{
        display:inline-block;
        margin-right: 3vw;
    }
    .topicNotice{
        font-size: larger;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85vw;
        margin-left: 3vw;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }
    .ratingNotice{
        text-align: center;
        width: 45vw;
        display: inline-block;
        padding: 0;
    }
    .dataNotice {
        text-align: center;
        width: 45vw;
        display: inline-block;
        padding: 0;
    }
    
    .modalOpenNotice{
        font-size: smaller;
        inset: unset;
        margin: 0 auto;
        -webkit-transform: unset;
                transform: unset;
        padding: 4%;
        border:none;
        border-radius: 0;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        max-height: none;
        max-width: none;
        margin: 0 auto;
    }

    .modalConfirmationDelete{
        inset: unset !important;
        padding: 4%;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        max-height: none;
        max-width: none;
        border:none !important;
        border-radius: 0 !important;
        -webkit-transform: unset;
                transform: unset;
        padding: 6%;
        padding-top: 33vh;
        
    }
    .confermaDelete{
        padding: 2% 4% !important;
        font-size: small;
        font-weight: 400;
        margin-top: 8%;
        
    }
}


.blueButtons{
    cursor: pointer;
    padding: 0.5% 1%;
    font-family: inherit;
    font-size: medium;
    font-weight: 400;
    background:  var(--blueAVR);
    border: solid 3px  var(--blueAVR);
    border-radius: 25px;
    color:white;

    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.blueButtons:active{
    color:var(--blueAVR);
    background: #f7f7f7;
    border: solid 3px  #f7f7f7;
}

.buttonCont{
    align-items: center;
}
.rating{
    cursor: pointer;
    font-size: x-large;
    background:  none;
    border:none;
    color:var(--blueAVR);
    margin:0.5%;
}
.green{
    color:rgb(13, 165, 13);
}

.red{
    color:red;
} 

.ratingMessage{
    color: red;
    font-size: small;
    text-align: center;
    
}

.noticesButtons{
    background-color: #f7f7f7;
    border: none;
    border-radius:30px;
    color: var(--blueAVR);
    cursor: pointer;
    font-size: medium;
    font-family: inherit;
    font-weight: 600; 
    padding: 3% 5%;
}

.noticesButtons:hover{
    background: var(--blueAVR);
    color: #ffffff;
}

.confermaDelete{
    /* padding: 1.5% 2%; */
}
@media (min-width: 600px){
    .blueButtons{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
}

@media (max-width: 600px){
    .ratingMessage {
        position:static;
        width: 100%;
    }
}



.paginationNumbers{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 20vw;
    width: 78vw;
    bottom: 0;
    height: 6vh;
    background: #f9f9f9;
}

.singleNumber{
    cursor: pointer;
    margin-left:0.7vw;
    margin-right:0.7vw;
    font-size: .9em
}

.singleNumber.active{
    color: #616161;
    font-weight:900;
}

.arrowsPagination{
    cursor: pointer;
    width: 0.5vw;
    margin-top:0.25vh;
}

.paginationNumbersInfo{
    font-weight: 200;
    position: absolute; 
    bottom:3vh;
    right: 10vw;;
}

#arrowLeft2{
    margin-right :1vh;
}
#arrowLeft3{
    margin-right :1vh;
}

#arrowRight1{
    margin-left :1vh;
}
#arrowRight2{
    margin-left :1vh;
}



@media (max-width: 600px) {
    .paginationNumbers{
        width:100vw;
        left: auto;
        bottom: 0vh;
        background: white;
        height: 6vh;
        font-size: medium;
    }
    
    .paginationNumbersInfo {
        display:none; 
    }
    
    #arrowLeft2{
        margin-right :2.5vh;
    }
    #arrowLeft3{
        margin-right :3.5vh;
    }
    #arrowRight1{
        margin-left :3.5vh;
    }
    #arrowRight2{
        margin-left :2.5vh;
    }
    .containerPaginationNumbers{
        white-space: nowrap;
        overflow: auto;
        max-width: 42%;
    }

    .containerPaginationNumbers::-webkit-scrollbar {
        width: 0;
      }
      
}
@media (min-width: 600px){
    .confermaEliminazioneBgModal{
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background-color: rgba(255, 255, 255, 0);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
    }
    
    .confermaEliminazioneDivModal{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 2%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 40vw;
        max-width: 50vw;
        max-height: 50vh;
        text-align: center;
    
    }


}


@media (max-width: 600px){
    .confermaEliminazioneBgModal{
        z-index:2;
        position: absolute;
        top:0;
        width:-webkit-fill-available;
        height:100vh;
        background-color: rgba(255, 255, 255, 0);
        -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(4px);
    }
    .confermaEliminazioneDivModal{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 10%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 60vw;
        max-width: 70vw;
        max-height: 70vh;
        text-align:center; 
    }
}



main{
    display: grid;
    grid-template-columns: 19vw auto;

}

.Appp2{
    margin-left:1vw;
    /* height: 100vh; */
    background: #f9f9f9;    
}

.containRight{
    display: grid;
    grid-template-rows: 15% 85% ;
}
.containersVari{
    padding:0% 2% 4% ;
   /*  height:63vh; */
    margin: 0 8%;
    width: 73%;
}

h1{
    margin: 3% 0 3% 5%;
}

/* Header */
.header2{
    height: 12vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.imgContUser{
    background-color: var(--blueAVR);
    border-radius: 100%;
    width: 3vw;
    height: 3vw;
    margin-right: 3%;
    cursor: pointer;
    color: white;
    font-size: x-large;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 30px;
    min-width: 30px;
    
}
.imgContMod{
    background-color: var(--blueAVR);
    border-radius: 100%;
    width: 3vw;
    height: 3vw;
    margin-right: 3%;
    cursor: pointer;
    color: white;
    font-size: x-large;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 35px;
    min-width: 35px;
    margin-bottom: 0.3vh;
}


#logoPrincipale{
    min-width: 50px;
    max-width: 60px;
    margin-top: 2vh;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}


#iconBell{
    color: black;
    width: 26px;
    height: 31px;
    margin-bottom: 0.5vw;
    position: absolute;
    top:5px;
    left:0;
}

#iconBellCircle{
    width:10px;
    position: absolute;
    left:21px;
    top:0;
}

#menuBars{
    display:none;
}
#bellContainer{
    position:relative;
    width:60px;
    height:40px;
}
#logoPrincipale2{
    display:none;
}



.anteprimaMessaggio{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 48vw;
}

/* .darkModeButton{
    font-size: x-large;
    padding: 0.3% 2%;
    cursor:pointer;
} */

@media (min-width: 600px) {
    .confermaEliminazioneBg{
        z-index:2;
        position: absolute;
        top:0;
        width:-webkit-fill-available;
        height:-webkit-fill-available;
        background-color: rgba(255, 255, 255, 0);
        -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(4px);
    }
    .confermaEliminazioneDiv{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 2%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 40vw;
        max-width: 50vw;
        max-height: 50vh;
        text-align:center; 
    }

    #modaleNotificheHeader{
        position: absolute;
        inset: 22vh auto auto 78vw !important;
        border: 2px solid rgb(0, 119, 188)  !important;
        background: rgb(0, 119, 188)  !important;
        border-radius: 7px;
        outline: none;
        padding: 1.5vw  !important;
        color: white;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px ;
        min-width: 18vw;
        max-width: 36vw;
        min-height: 8vh;
        max-height: 34vh;
        overflow-y:auto;
    }
    
    #modalMenuHeader{
        position: absolute;
      /*   right: 2vw;
        top: 15vh; */
        inset: 14% 40px 40px auto !important;
        border: 1px solid rgb(204, 204, 204)  !important;
        background: var(--blueAVR)  !important;
        border-radius: 5px;
        outline: none;
        padding: 2%  !important;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        box-shadow: rgb(193 213 226) 5px 5px 15px;
        color: white;
    }

    #modalMenuHeader p{
        padding:0.5vw;
    }

    .eliminaAccountButton{
        margin:3vh auto;
        cursor:pointer;
    }
    .eliminaAccountButton:hover{
        font-weight: bold;
    }
    .esciButton{
        text-decoration:none;
        cursor:pointer;

    }

    .esciButton:hover{
        font-weight: bold;

    }



}

@media (max-width: 600px) {
    .Appp2{
        margin-left:0;
    }
    main{
        grid-template-columns: 0% auto;
    }
    .containerLogin{
        margin-left: 0%;
    }
    .bodyLogin{
        width: 100vw;
        font-size: smaller;
    }
    .containersVari{
        margin-top: 14vh;
    }
    .containerRegister{
        width: 100vw;
    }
    .bodyRegister{
        width: auto;
        /* padding: 5%; */
    }
    #logoPrincipale{
        display:none;
    }
    #titoloSidebar{
        display:none;
    }
    .header2{
        width: auto;
        padding-bottom: 1vh;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(197, 197, 197);
        padding-top: 1vh;
        position: fixed;
        background: white;
        z-index: 1;
        height: 11vh;
        top:0;
    }
    #menuBars{
        display:block;
    }

    #logoPrincipale2{
        display:block;
        margin-left:30vw;
        /* margin-top:1%; */
    }
    #bellContainer{
        width: 10px;
        height: 25px;
    }
    #iconBell{
        width: 20px;
        height: 25px;
        top: 0;
    }
    #iconBellCircle{
        left: 16px;
        top: -5px;
    }
    .imgContMod{
        width: 11vw;
        height: 11vw;
        font-size:large;
    }
    .imgContUser{
        width: 30px;
        height: 30px;
        font-size:large;
    }

    #modalMenuHeader{
        padding: 7% !important;
        inset:auto;
        top:14% !important;
        left:44% !important;
        width: 40vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        color: white;

        border: 1px solid rgb(204, 204, 204)  !important;
        background: var(--blueAVR)  !important;
        border-radius: 5px;
        outline: none;
    }
    #modalMenuHeader h4{
        margin:5% 0;
        font-size:large;

    }
    .anteprimaMessaggio{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 48vw;
    }
    #modaleNotificheHeader{    
        min-width: 51vw;
        max-width:75vw;
        height:-webkit-fit-content;
        height:-moz-fit-content;
        height:fit-content;
        padding:5% !important;
        border: 2px solid rgb(0, 119, 188)  !important;
        background: rgb(0, 119, 188)  !important;
        border-radius: 7px;
        color: white;
        box-shadow: rgb(5 78 125) 2px 2px 7px ;
        outline: none;
        overflow-y:auto; 
        position: -webkit-sticky; 
        position: sticky;
        top: 15vh  !important;
        left: 16vw  !important;
        z-index:100;
    }
    .esciButton{
        text-decoration:none;
    }
    .esciButton  h3{
        text-decoration:none;
        cursor:pointer;
        font-size:large;
        font-weight:bold;
        margin:3% 0;
    }
    .eliminaAccountButton{
        margin:8% 0;
        font-size:large;
        font-weight:bold;

    }

    .confermaEliminazioneBg{
        z-index:2;
        position: absolute;
        top:0;
        width:-webkit-fill-available;
        height:100vh;
        background-color: rgba(255, 255, 255, 0);
        -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(4px);
    }
    .confermaEliminazioneDiv{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 10%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 60vw;
        max-width: 70vw;
        max-height: 70vh;
        text-align:center; 
    }
    .confermaEliminazioneDiv select{
        width:50vw;
    }

}

.buttonsContMsg{
    text-align: center;
}

#divTopicNot{ 
    display: grid;
    grid-template-columns: 50% 50%;
    /* text-align: center; */
}

#delNotMod{
    width: 9vw;
    height: 5.5vh;
    margin-top: 4%;
    margin-left: 30%;
}

.textContainer{
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-top: 4vh;
    overflow-y:auto;
    min-height: 10vh;
    max-height:24vh;
    text-align: justify;
    padding:2%;
    font-weight: 200;
}

.headModalColumns{
    display:grid;
    grid-template-columns: 65% 40%;
    margin-bottom:3vh;
    grid-row-gap: 1vh;
}

.modalOpenMessage{
    position: absolute;
    inset: 50% auto auto 50%;
    border: 2px solid rgb(0, 119, 188);
    background: rgb(255, 255, 255);
    border-radius: 0px;
    outline: none;
    padding: 3%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    min-width: 40vw;
    max-width: 50vw;
    max-height: 50vh;
}

.modalOpenMessageReply{
    position: absolute;
    inset: 50% auto auto 30%;
    /* border: 2px solid rgb(0, 119, 188); */
    background: rgb(255, 255, 255);
    /* border-radius: 0px; */
    outline: none;
    padding: 1%;
    /* margin-right: -50%; */
    /* transform: translate(-50%, -50%); */
    box-shadow: rgb(8 37 56) 2px 2px 7px;
    min-width: 52vw;
    max-width: 50vw;
    max-height: 24vh;
}

.replyMessageCont{
    text-align:center;
    padding-top: 3vh;
}
.replyMessageCont textarea{
    border: none;
    border-radius: 10px;
    min-width: 30vw;
    min-height: 10vh;
    background-color: rgb(241, 241, 241);
    padding: 2%;
    font-family: inherit;
    caret-color: var(--blueAVR);
    margin-top:3vh;
    text-align: justify;
}
#clickedNoticeTitle{
    margin: 0 0 0 1vw;
    font-weight: 500;
}


@media (max-width: 600px) {
    #delNotMod{
        position: absolute;
        bottom: 5vh;
        left: 35vw;
        width: 32vw;
        font-weight: 400;
        max-height: 6vh;
        margin: 0 auto;
        font-size: small;
    }
    .textContainer{
        max-height: inherit;
        padding: 4% 4%;
        margin-top: 4vh;
        font-size: medium;
        font-weight: 200;
            
    }
    #clickedNoticeTitle{
        font-weight: 500;
        text-align: justify;
        max-width: 72vw;
        margin: 5vh auto 2vh 7vw;
    }
    #divTopicNot {
        display:block;
        text-align: center;
    }
    #contenutoSingleNotice{
        margin:9% 0 ;
    }
    .modalOpenMessage{
        font-size: smaller;
        inset: unset;
        -webkit-transform:unset;
                transform:unset;
        border:none;
        border-radius: 0;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        padding: 5%;
        min-width: none;
        min-height: none;
        max-height: none;
        max-width: none;
        margin: 0 auto;
    }

    .modalOpenMessageReply{
        font-size: smaller;
        inset: unset ;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        min-width: none;
        min-height: none;
        max-height: none;
        max-width: none;
        margin: 0 auto;
        padding: 4%;
        font-weight: 200;
        -webkit-transform:unset;
                transform:unset;
        border:none;
        border-radius: 0;
    }
    .replyMessageCont textarea{    
        min-width: 69vw;
        min-height: 36vh;
        margin-top: 8%;
    }
    .replyMessageCont h4{    
        margin-top: 11%;
        font-weight: 400;
    }
    .headModalColumns{
        display:block;
    }
    .headModalColumns div{
        margin-top:7%
    }
    .buttonsContMsg{
        position: absolute;
        bottom: 4vh;
        left: 0;
        width: -webkit-fill-available;
    }
}
.singleMessage{
    cursor:pointer;
    display:grid;
    grid-template-columns: 3% 15% auto 25%;
    /* grid-auto-columns: minmax(min-content, 3%) minmax(min-content, 13%) minmax(min-content, auto) minmax(min-content, 25%); */
    font-weight: 300;
    padding: 1em;
    grid-gap: .5em;
    gap: .5em;
}

.singleMessage .subjectMessage{
    padding: 0 0 .5em;
    margin: 0;
}

.singleMessage:hover{
    color:var(--blueAVR);
    font-weight:bold;
}
.listaNot a{
    cursor: pointer;
    text-decoration: none;
    color: unset;
}

.dateMessage{
    text-align: center;
    font-size: .8em;
}

.fromMessage{
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8em;
}

#usersIcon{
    cursor: help;
}

#usersIcon:hover + .tooltipUsers{
    opacity:1;
}

.tooltipUsers{
    background-color: rgb(245, 245, 245);
    padding: 0.5%;
    border-radius:5px;
    position:absolute;
    width: 5vw;
    text-align: center;
    left: 33vw;
    top: 32vh;
    opacity: 0;
    font-weight:400;
    font-size: small;
}

.clockIconMsgs{
    cursor: help;
    margin-left: 11vw;
    padding: .2em;
    box-sizing: content-box;
}

.clockIconMsgs:hover + .tooltipClockMsgs{
    opacity:1;
}

.tooltipClockMsgs{
    background-color: rgb(245, 245, 245);
    padding: 0.4%;
    border-radius:5px;
    position:absolute;
    width: 4vw;
    text-align: center;
    left: 80vw;
    top: 32vh;
    opacity: 0;
    font-weight:400;
    font-size: small;
}

#usersIconSent{
    cursor: help;
}

#usersIconSent:hover + .tooltipUsers{
    opacity:1;
}

.busta{
    text-align: center;
}

.headListaMessages{
    display: flex;
    font-weight: 500;
    margin-left: 3vw;
    text-align: center;
    width: 83%;
    justify-content: space-between;
}

.textShortform{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 .5em;
}
@media (min-width: 600px){

    .messageTypeCircle{
        margin-left:0.5vw; 
        width:0.5vw;
        height:0.5vw;
        border-radius:100%;
        display:inline-block;
    }
    .tooltipTypeMessage{
        opacity:0;
        display: inline-block;
        font-size:smaller;
        background-color:var(--blueAVR);
        color:white;
        margin: 0 1%;
        padding: .5em;
        position:absolute;
        top: 3vh;
        /* left: 11vw; */
    }
    .messageTypeCircle:hover + .tooltipTypeMessage{
        opacity:1;
    }
    .dateMessage{
        position:relative;
    }
    .singleMessage{
        margin-bottom: 2.5vh;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background:white;
    }
    .singleMessage:hover{
        border-radius: 2px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }


    .fadeIn {
        -webkit-animation: fadeInAnimation 1s;
                animation: fadeInAnimation 1s;
    }

    @-webkit-keyframes fadeInAnimation {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @keyframes fadeInAnimation {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}

@media (max-width: 600px) {
    .headListaMessages {
        display:none;
    }
    .singleMessage{
        box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 53%);
        border-radius: 2px;
        min-height: 11vh;
        margin-bottom: 2vh;
        position: relative;

        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(3, 1fr);
        align-items: center;
        text-align: left;
    }
    .singleMessage:hover{
        border-radius: 0px;
    }

    .busta{
        text-align: center;
        grid-area: 1 / 1 / 2 / 2;
        padding:0 2vw;
    }
    .singleMessage .subjectMessage{
        /* grid-column-start: 2; */
        grid-area: 2 / 1 / 3 / 8;

        font-size: larger;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
        margin-left:3vw;
        width: 97%;
    }
    .fromMessage {
        grid-area: 1 / 2 / 2 / 5;

    }
    .dateMessage {
        display: flex;
        align-items: center; 
        justify-content: space-evenly;
        padding:3%;
        font-size: .8em;

        grid-area: 1 / 5 / 2 / 8; 
    }

    #orologioIcon{
        margin:2% 0;
    }
    .textShortform{
        grid-area: 3 / 1 / 4 / 8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        margin-left:3vw;
    }
    .messageTypeCircle{
        display:none;
    }
    .tooltipTypeMessage{
        display:none;
    }
    
}


@media (max-width: 600px) {
.storeMessageButton {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.archiviaButton{
    padding:1% 2%;
}
}


@media (min-width: 600px){
    
    .containerMessaggiSuperadminPage{
        padding: 3% 2%;
        margin: 0 8%;
        width: 73%;
        font-weight: 300;
    }


}

@media (max-width: 600px){

    .containerMessaggiSuperadminPage{
        margin-top: 16vh;
        width: 90vw;
        text-align: center;
        padding: 0 5vw;
    }

    .containerMessaggiSuperadminPage select{    
        width: 50vw;
    }
}


.containerNewMsg {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.headNewMsg {
    display: flex;
    color: var(--blueAVR);
    width: 76vw;
}
.headNewMsg h2{
    font-weight: 400;
    margin-top: 0;
}

.bodyNewMsg {
    width: 45vw;
    padding: 0 3%;
    border-radius: 15px;
    text-align: center;
}

.inputTopic {
    border: none;
    border-radius: 20px;
    min-height: 25px;
    width: 16vw;
    background-color: rgb(241, 241, 241);
    padding: 1%;
    font-family: inherit;
}

select {
    border: none;
    border-radius: 20px;
    min-height: 25px;
    width: 16vw;
    background-color: rgb(241, 241, 241);
    padding: 1%;
    font-family: inherit;
}

select:focus-visible {
    outline: none;
    border: none;
}

option:focus-visible {
    outline: none;
}

.inputTopic:focus-visible {
    outline: none;
}

textarea:focus-visible {
    outline: none;
}
#textareaSubject{
    border: none;
    border-radius: 2px;
    min-width: 25vw;
    height: 3vh;
    padding: 2%;
    font-family: inherit;
    caret-color: var(--blueAVR);
    text-align: justify;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}


.buttonWrap {
    display: flex;
    margin-top: 8%;
    margin-bottom: 3%;
    justify-content: space-around;
}

h4 {
    margin:  1%;
    font-weight: bold;
}

.filterBy {
    position: absolute;
    top: 22%;
    left: 64%;
}

#faInfoConsensus {
    color: var(--blueAVR);
    cursor: help;
    width: 2vw;
    height: 3vh;
    /*  position: absolute;
    right: 25vw; */
}

.infoConsensusTooltip {
    background-color: rgb(245, 245, 245);
    padding: 1%;
    border-radius: 5px;
    position: absolute;
    width: 20vw;
    right: 0vw;
    bottom: 19vh;
    opacity: 0;
    transition: all 0.5s;
    text-align: justify;
    font-size: small;
    font-weight: 400;
    max-width: 23vw;

}

#faInfoConsensus:hover+.infoConsensusTooltip {
    opacity: 1;
    right: 3vw;

}

#checkboxConsensus {
    visibility: hidden;
}

.main {
    display: block;
    position: relative;
    padding-left: 45px;
    cursor: pointer;
}

/* Creating a custom checkbox based on demand */
.geekmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 5px;
    background-color: rgb(241, 241, 241); 
}

/* Specify the background color to be shown when hovering over checkbox */
.main:hover input~.geekmark {
    background-color: rgb(245, 245, 245);
}

/* Specify the background color to be shown when checkbox is active */
/* .main input:active~.geekmark {
    background-color: red;
} */

/* Specify the background color to be shown when checkbox is checked */
.main input:checked~.geekmark {
    background-color: var(--blueAVR);
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Display checkmark when checked */
.main input:checked~.geekmark:after {
    display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and showing only two border to make it look like a tickmark */
.main .geekmark:after {
    left: 7.5px;
    bottom: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
#consensusNewMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh;
}


.labelAggiungi{
    margin:1%;
    cursor:pointer;
    display:inline-block;
}

.labelAggiungi:hover{
    font-weight:bold;
    color: var(--blueAVR);
}

@media (min-width: 600px) {
    #newMessTitle{
        margin: 5vh auto 1vh;
        font-size:x-large;
    }
    #textareaTesto {
        border: none;
        border-radius: 2px;
        min-width: 30vw;
        min-height: 10vh;
        padding: 2%;
        font-family: inherit;
        caret-color: var(--blueAVR);
        text-align: justify;
        
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    #newMsgButtonsContainer .confermaDelete{
        margin:1vh;
    }

}
@media (max-width: 600px) {
    .filterBy {
        position: static;
        font-size: small;
        width: 90vw;
        text-align: center;
        margin-top: 2vh;
        margin-left: 5vw;
    }
    .filterBy label{
        font-size: inherit;
        display: inline;
        font-weight: 200 !important;
    }

    .filter{
        width: 20vw;
    }
    .containerNewMsg{
        width:100vw;
    }
    .headNewMsg h2{
        width: 100vw;
        font-size: large;
        text-decoration: none;
        margin-top: 4vh;
        text-align: center;
    }
    .bodyNewMsg {
        width:100vw;
    }
    .bodyNewMsg h4{
        font-weight: 200;
    }
    .bodyNewMsg select{
        margin-bottom:2vh;
        margin-top:2vh;
        width:70vw;
    }
    .bodyNewMessage .errorP{
        margin: 0;
    }
    #textareaSubject{
        margin-top:2vh;
        margin-bottom:1vh;
        width:70vw;
        height:4vh;
    }
    #textareaTesto {
        border: none;
        border-radius: 2px;
        padding:2%;
        font-family: inherit;
        caret-color:var(--blueAVR);
        text-align: justify;
        box-shadow:0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        /* transition: all 0.3s cubic-bezier(.25,.8,.25,1);  */
        margin-top:2vh;
        width:70vw;
        min-height:25vh; 
    }
    #consensusNewMsg{
        font-size: small;
    }
    #consensusNewMsg label{
        padding-left: 9vw;
    }
    #faInfoConsensus {
        display:none;
    }
    .infoConsensusTooltip {
        display:none;
    }
    #newMsgButtonsContainer .confermaDelete{
        margin-top:2vh;
    }
}

.whiteButtons{
    cursor: pointer;
    padding: 0.5% 1%;

    font-size: medium;
    font-weight: 500;
    font-family: inherit;
    background: #f9f9f9;
    border: solid 3px #f9f9f9;
    /* border: solid 2px var(--blueAVR); */
    border-radius: 25px;
    color:var(--blueAVR);

    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.whiteButtons:active{
    color: white;
    background: var(--blueAVR);
    border: solid 3px  var(--blueAVR);
}

.consensus{
    font-size: medium;
    margin: 2%;
}

#closePopUp{
    cursor: pointer;
    font-size: xx-large;
    font-weight: 500;
    color: var(--blueAVR);
    position: absolute;
    top: 9px;
    right: 1vw;
}
@media (min-width: 600px){
    .whiteButtons{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
}

@media (max-width: 600px) {
    #closePopUp{
        font-size:xx-large;
        top: -2px;
        right: 11px;
    }
}
.containerNewNot{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:100%;
}

.headNewNot {
    display: flex; 
    color: var(--blueAVR);
    width: 76vw;
}

.bodyNewNot{
    width:45vw;
    padding:0 3%;
    border-radius:15px;
    text-align: center;
}

.inputTopic{
    border: none;
    border-radius: 2px;
    min-height: 25px;
    width: 30vw;
    padding:1%;
    font-family: inherit;
    text-align:center;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

select{
    cursor: pointer;
    border: none;
    border-radius: 2px;
    min-height: 25px;
    width: 16vw;
    padding:1%;
    font-family: inherit;
    margin-bottom: 1%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

select:focus-visible{
    outline: none;
    border:none;
}

option:focus-visible{
    outline: none;
}

.inputTopic:focus-visible{
    outline: none;
    caret-color:var(--blueAVR);
}



.buttonWrap{
    display: flex;
    margin-top: 8%;
    margin-bottom:3%;
    justify-content: space-around;
}
.errorP{
    color:red;
    margin:1%;
}
#faClockIcon{
    margin-left: 1vw;
    margin-right:1vw;
}
@media (min-width: 600px) {
    #newNotTitle {
        margin: 5vh auto;
    }
    .containerNewNot textarea{
        
        border: none;
        border-radius: 2px;
        min-width: 30vw;
        min-height: 10vh;
        padding:2%;
        font-family: inherit;
        caret-color:var(--blueAVR);
        text-align: justify;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    
    .containerNewNot textarea:focus-visible{
        outline: none;
    }
    .bodyNewNot .errorP{
        margin-top:4vh;
    }
    .newNotButtonContainer{
        margin-top:3vh;
    }


}
@media (max-width: 600px) {
    .containerNewNot{
        width:100vw;
    }
    #newNotTitle{
        width: 100vw;
        font-size: large;
        text-decoration: none;
        margin-top: 4vh;
        text-align: center;
        margin-bottom:0vh;
    }
    .bodyNewNot{
        width:100vw;
    }
    .bodyNewNot input{
        margin-top:2vh;
        width:70vw;
    }
    .containerNewNot textarea{
        border: none;
        border-radius: 2px;
        padding:2%;
        font-family: inherit;
        caret-color:var(--blueAVR);
        text-align: justify;
        box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        /* transition: all 0.3s cubic-bezier(.25,.8,.25,1);  */
        margin-top:2vh;
        width:70vw;
        min-height:25vh; 
    }
    
    .containerNewNot textarea:focus-visible{
        outline: none;
    }

    .bodyNewNot h4{
        font-weight: 200;
    }

    #faClockIcon{
        margin-left: 6vw;
    }
    .errorP{
        margin-top:4vh;
        font-size: small;
    }
}

 .errorText{
    color:red;
    background-color:white;
    font-size:small;
    padding:1%;
    width:-webkit-max-content;
    width:max-content;
    position:absolute;
    right:-2vw;
 }

@media (min-width: 600px) {
    #containerChatOuter{
        width: 60vw;
        padding: 1% 0;
    }
    #subjectChat{
        text-align: left;
        margin-left: 3vw;
    }
    #consensusChatPage{
        margin-left: 3vw;
    }

    #containerChatOuter textarea{
        width:85%;
        font-family: inherit;
        caret-color: var(--blueAVR);
        border: none;
        border-radius: 5px;
        padding: 1vh 2vh;
        height: 4vh !important;
        resize: none;
        background: #f9f9f9;

    }
    .storeMessageButton{
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
    }
    .storeMessageButton:hover{
        color: var(--blueAVR);
        font-weight: 700;
    }

    
    #containerChatInner{
        height:53vh;
        display: flex;
        flex-direction: column;
        padding:2% 4% 0 4%;
        overflow: auto;
        margin-top:2vh;
    }
    #containerChatInnerSuperadmin{
        height:63vh;
        display: flex;
        flex-direction: column;
        padding:2% 4% 0 4%;
        overflow: auto;
        margin-top:2vh;
    }

    #replyContainer{
        /* width: 60vw; */
        text-align: justify;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 0.5%;
        margin: 2vh auto;
        display: flex;
        justify-content: space-between;
    }
    
    .messageInbox{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding:1%;
        display: flex;
        justify-content: flex-start;
    }
    .messageSent{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding:1%;
        display: flex;
        justify-content: flex-end;
    }
    .testoLui{
        background: rgb(140 204 220); 
        border-radius:5px;
        min-width:20vw;
        max-width:28vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 1%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }
    .testoIo{
        background: rgb(221 221 221);
        border-radius:5px;
        min-width:20vw;
        max-width:28vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 1%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }
    .replyChatButton{
        width: 3vw;
        height: 3vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        display:inline-block;   
        margin:0; 

    }
    .replyChatButtonAddFiles{
        width: 3vw;
        height: 3vw;
        border: none;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        display:flex;
        color: white;
        align-items: center;
        justify-content: center;
        position:relative;
    }
    .replyChatButtonAddFiles label{
        cursor: pointer;
        padding:20%;
    }

    .fileSelezionatiText{
        position:absolute;
        bottom:-3.5vh;
        
        color: black;
        font-size:small;
    }
    .dataEora{
        margin-top:1%;
        font-size: x-small;
        text-align: right;
    }
    .backButton{
        /* display:none; */
        width: 35px;
        height: 35px;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        -webkit-transform: rotateY(3.142rad);
                transform: rotateY(3.142rad);
        margin-left: 3vw;
    }
    #headChatPage{
        display: grid;
        grid-template-columns: 15% 50% auto;
        align-items: center;
        width: 60vw;
    }
    .archiviaButton{
        text-align: center;
        max-width: -webkit-max-content;
        max-width: max-content;
        padding:2% 3%;
    }
    .immagineApertaDiv{
        opacity: 1;
        z-index: 5;
        transition: all 0.5s;
        position: fixed;
        top: 0em;
        right: 1em;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
    }
    .immagineApertaDiv img{
        cursor: pointer;
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
    
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
}

@media (max-width: 600px) {
    .fileSelezionatiText{
        position: absolute;
        top: 7vh;
        right: 2vw;
        color: black;
        font-size: small;
    }
    #chatTitle{
        font-size: large;
        text-decoration: none;
        text-align: center;
        padding: 1% 3%;
    }
    #containerChatOuter {
        width:100vw;
    }
    #containerChatInner{
        max-height:50vh;
        display: flex;
        flex-direction: column;
        padding:2% 4% 0 4%;
        overflow: auto;
        margin-bottom: 12vh;
    }
    .messageSent{ 
        display: flex;
        justify-content: flex-end;
        padding:1%;
    }
    .messageInbox{
        display: flex;
        justify-content: flex-start;
        padding:1%;
    }
    .testoLui{
        background: rgb(140 204 220); 
        border-radius:5px;
        min-width: 30vw;
        max-width: 65vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }

    .testoIo{
        background: rgb(231 231 231 / 52%);
        border-radius:5px;
        min-width: 30vw;
        max-width: 65vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }
    #subjectChat{

        width: auto;
        margin-top: 0vh;
        font-size: small;
        padding: 1vh 5vw;
        display: block;
    }
    #replyContainer{
        display: flex;
        justify-content: space-between;
        width: 95vw;
        text-align: justify;
        border: 1px solid rgb(160, 160, 160);
        border-radius: 3px;
        padding: 0.5%;
        margin: 1vh;
        position: fixed;
        bottom: 0vh;
        background: white;
    }
    #replyContainer textarea{
        width:70%;
        font-family: inherit;
        caret-color: var(--blueAVR);
        border: none;
        border-radius: 5px;
        padding: 1vh 2vh;
        height: 7vh !important; 
        resize: none;
    }
    .replyChatButton{
        width: 10vw;
        height: 10vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        margin: 0 1%;
    }
    .replyChatButtonAddFiles {
        width: 10vw;
        height: 10vw;
        border: none;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        display: flex;
        color: white;
        align-items: center;
        margin: 0 1%;
        justify-content: center;
        /* position: relative; */
    }
    .dataEora{
        margin-top:1%;
        font-size: x-small;
        text-align: right;
    }

    .backButton{
        width: 10vw;
        height: 10vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        -webkit-transform: rotateY(3.142rad);
                transform: rotateY(3.142rad);
        /* position:absolute;
        left: 5vw; */
    }
    #headChatPage{
        /* text-align: center;
        width: 90vw; */
        width: 100vw;
        /* display: flex; */
        align-items: center;
        justify-content: flex-end;
    }
    #consensusChatPage{
        width: auto;
        margin-top: 0vh;
        font-size: small;
        padding: 1vh 5vw;
        display: block;
    }
          
    .immagineApertaDiv{
        opacity: 1;
        z-index: 5;
        transition: all 0.5s;
        position: fixed;
        top: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);

    }
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
    .immagineApertaDiv img{
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
}

.inputData{
    cursor: pointer;
}
.resultsContainer a {
    text-decoration: none;
    color: unset;
}


@media (min-width: 600px){
    .cerca{
        position: absolute;
        padding:0;
        top: 18vh;
        right: 13vw;
        width: 3vw;
        height: 3vw;
        border-radius: 5vw;
        display:flex;align-items: center;
        justify-content: center;
    }
    .cerca:active{
        border:none;
    }

    .filterContainer{
        background: var(--blueAVR);
        color: white;
        position: -webkit-sticky;
        position: sticky;
        width: auto;
        z-index:1;
        top:0;
        /*
        background: rgb(0,119,188);
        background: linear-gradient(180deg, rgba(0,119,188,1) 55%, rgba(249,249,249,0) 100%);*/
        transition: all 1s;
        height: 50vh;
        padding: 3% 2%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1900' height='900' preserveAspectRatio='none' viewBox='0 0 1900 900'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1038%26quot%3b)' fill='none'%3e%3crect width='1900' height='900' x='0' y='0' fill='rgba(0%2c 119%2c 188%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c872.035C169.312%2c910.2%2c368.849%2c892.44%2c501.956%2c781.059C633.342%2c671.118%2c623.787%2c471.625%2c674.185%2c307.89C717.415%2c167.443%2c772.72%2c35.216%2c776.743%2c-111.679C781.402%2c-281.806%2c798.343%2c-467.529%2c699.207%2c-605.866C597.896%2c-747.239%2c422.6%2c-837.135%2c249.019%2c-848.08C85.75%2c-858.375%2c-48.274%2c-735.681%2c-194.444%2c-662.217C-313.417%2c-602.422%2c-427.75%2c-545.142%2c-528.03%2c-457.541C-642.499%2c-357.545%2c-806.43%2c-269.156%2c-817.893%2c-117.595C-829.44%2c35.084%2c-664.557%2c136.701%2c-582.764%2c266.139C-513.832%2c375.224%2c-464.381%2c490.398%2c-374.929%2c583.4C-263.335%2c699.424%2c-157.041%2c836.636%2c0%2c872.035' fill='%23006ead'%3e%3c/path%3e%3cpath d='M1900 1593.613C2025.589 1564.662 2076.742 1413.365 2186.44 1345.71 2299.371 1276.061 2495.743 1317.821 2546.167 1195.094 2597.2709999999997 1070.71 2423.9049999999997 960.403 2399.044 828.248 2375.87 705.063 2485.277 558.518 2407.867 459.931 2331.292 362.40700000000004 2174.837 394.078 2051.161 385.193 1950.905 377.991 1857.454 408.34 1757.056 413.177 1617.057 419.922 1460.4180000000001 339.48699999999997 1345.135 419.206 1231.194 497.997 1203.728 660.744 1198.837 799.188 1194.208 930.224 1265.054 1045.829 1318.774 1165.437 1372.853 1285.843 1407.66 1422.45 1513.93 1500.737 1622.7 1580.865 1768.355 1623.96 1900 1593.613' fill='%230081cb'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1038'%3e%3crect width='1900' height='900' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
        
    }

    .filterContainer--closed{
        background: var(--blueAVR);
        color: white;
        position: -webkit-sticky;
        position: sticky;
        width: auto;
        z-index:1;
        cursor:pointer;
        top:0;
        transition: all 1s;
        height: 9vh;
        padding: 1% 2%;

    } 
    .filterContainer--closed:hover{
        text-decoration: underline;
    }

    .bodyFilterContainer{
        opacity: 1;
        visibility: visible;
        transition: all 1.2s ease-in;
       /*  display:block; */

    }
    .bodyFilterContainer--closed{
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
       /*  display:none; */
    } 

    .searchPageContainer label{
        display:inline;
    }

    .searchPageContainer input{
        height: 3vh;
        border-radius: 0.8em;
        margin: 0 auto;
        padding: 0 0.5vw;
    }
    #containerFiltriData{
        height: 6vh;
        display: inline-grid;
        grid-template-columns: 17.5vw 17vw;
        align-items: center;
        width: 35vw;
        padding: 1vh;
    }
    .bottoneCancellaData{
        border: none;
        border-radius: 7vh;
        width: 3vh;
        height: 3vh;
        margin-left: 1vw;
        font-weight: 900;
        cursor: pointer;
    }
    
    .cancellaFiltriIcon{
        margin-left: 1vw;
        cursor: pointer;
    }
    .backButtonSearch{
        width: 3vw;
        height: 3vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: white;
        border-radius: 100%;
        color: var(--blueAVR) ;
        -webkit-transform: rotateY(3.142rad);
                transform: rotateY(3.142rad);
    }
    .backButtonSearch a{
        text-decoration: none !important;
        color: var(--blueAVR) !important;
    }
    .resultsContainer{
        padding: 2% 3% 8%;
        width: auto;
        height: 70vh;
        overflow-y: scroll;
    }

    .boxInputSearch{
        display: grid;
        grid-template-columns: 15vw 15vw 3vw;
        align-items: center;
        height: 6vh;
        width: 32vw;
        margin-right:6vw;
        padding: 1vh;
    }

    .cancellaFiltri{
        padding:2vh 1vh;
    }

    .headFilterContainer{
        margin-bottom: 2vh;
        font-size: larger;
        font-weight: 500;
        display: grid;
        grid-template-columns: 15% 50%;
        align-items: center;
        height: 8vh;
    }
    .searchPageContainer{
        background:#f7f7f7;
    }
    .headFilterContainer p{
        text-align: center;
    }
    .angleUp{
        position:absolute;
        right: 5vw;
        bottom: 3vh;
        font-size: xx-large;
        cursor:pointer;
    }
    .down{
        bottom: 3vh;
        -webkit-transform: rotateZ(3.142rad);
                transform: rotateZ(3.142rad);
    }

}

@media (max-width: 600px){
    .cerca{
        width: 11vw;
        height: 11vw;
        font-weight: 600;
        font-size: small;
        position: absolute;
        top: 15vh;
        right: 7vw;
        border-radius: 10vw;
    }
    .cerca:active{
        border:none;
    }
    .filterContainer{
        padding:4vw;
        background: var(--blueAVR);
        color: white;
        transition: all 1s;
        position: -webkit-sticky;
        position: sticky;
        width: auto;
        z-index:1;
        top:0;  

        height: 100vh;
    }
    .filterContainer--closed{
        padding:4vw;
        background: var(--blueAVR);
        color: white;
        transition: all 1s;
        position: -webkit-sticky;
        position: sticky;
        width: auto;
        z-index:1;
        cursor:pointer;
        top:0;

        height: 14vh;

    } 
    .bodyFilterContainer{
        opacity: 1;
        visibility: visible;
        transition: all 1.2s ease-in;
       /*  display:block; */
        text-align: center;
        margin-top:5vh;
    }

    .bodyFilterContainer--closed{
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
       /*  display:none; */
        text-align: center;
        margin-top:5vh;
    } 
    .angleUp{
        position:absolute;
        right: 5vw;
        bottom: 7vh;
        font-size: x-large;
        cursor:pointer;
    }
    .down{
        bottom: 2vh;
        -webkit-transform: rotateZ(3.142rad);
                transform: rotateZ(3.142rad);
    }

    .searchPageContainer input{
        height:4vh;
        border-radius: 0.8em;
        margin-top:2vh;
        padding: 0 3vw;
    }
    .bottoneCancellaData{
        border: none;
        border-radius: 7vh;
        width: 8vw;
        height: 8vw;
        margin-left: 5vw;
        font-weight: 900;
        cursor: pointer;
    }
    .cancellaFiltri{
        text-align: start;
        position:absolute;
        bottom: 7vh;

    }
    .cancellaFiltriIcon{
        margin-left: 4vw;
    }
    .searchPageContainer{
        width:100vw;
    }
    .resultsContainer{
        padding: 3%;
        margin-bottom: 5vh;
        font-size: small;
    }
    .backButtonSearch{
        width: 10vw;
        height: 10vw;
        border: none;
        cursor: pointer;
        font-size: large;
        border-radius: 100%;
        -webkit-transform: rotateY(3.142rad);
                transform: rotateY(3.142rad);
        background: white;
        color: var(--blueAVR);
    }
    .backButtonSearch a{
        color:var(--blueAVR);
    }
    .headFilterContainer{
        display: grid;
        grid-template-columns: 22% 66%;
        align-items: center;
    }
    .headFilterContainer p{
        text-align: justify;
    }
}

@media (min-width: 600px) {
    .statsPage{
        background:#f7f7f7;
    }
    .statsPageContainer{
        padding:2%;
    }
    .bodyGridStats{
        min-height:45vh;
        width: 66vw;
        display: grid;
        grid-template-columns: 17% auto 17%;
        padding: 1% 5%;
    }
    .statsPageContainer button{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        min-height: 40px;
        cursor:pointer;
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .statsPageContainer button:hover{
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    }
    .clicked{
        background: var(--blueAVR) !important;
        color:white;
    }
    .statsPageContainer input{
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .selectedBlue{
        background: var(--blueAVR) !important;
        color:white;
    }
    .selectedGreen{
        background: rgb(8, 167, 8) !important;
        color:white;
    }
    .selectedRed{
        background: red !important;
        color:white;
    }
    .selectedOrange{
        background: rgb(255, 208, 0) !important;
    }
    .leftButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .rightButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bottomButtonsStats{
        text-align: center;
    }
    .chartContainer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .statsButtons{
        display: inline;
        padding: 0.5% 2%;
        margin: 0 2%;
    }
    .statsButtonContainer{
        margin-top:3%;
        margin-left:6%;
    }
    .testoTempistiche{
        display:inline-block;
        margin:0 5%;
    }
    .containerTempistiche{
        margin:1%;
        overflow-y: auto;
        min-height: 58vh;
    }
    .headContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
    }
    .bodyContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
        font-weight: 300;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        padding: 1%;
        background-color: white;
        margin: 1%;
    }
    .containerTempistiche span{
        text-align: center;
    }
    .oreRisposta{
        font-weight: 600;
        /* color:var(--blueAVR); */
    }
    .avvisoChart{
        position: absolute;
        color: red;
    }
    .headStatsPage{
        margin:0 5%;
        font-weight: 300;
    }
    .selectTempistiche{
        margin: 0 0 0 2vw;
    }
}

@media (max-width: 600px) {
    .statsPage{
        background:#f7f7f7;
    }
    .statsPageContainer{
        padding: 5vw;
        width: 90vw;
    }
    .bodyGridStats{
        padding: 2% 5%;
    }
    .statsButtonContainer{
        margin-top: 17vh;
        width: 90vw;
        display: flex;
    justify-content: space-around;
    }
    .statsPageContainer button{
        min-height: 40px;
        cursor:pointer;
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .statsPageContainer button:hover{
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    }
    .statsPageContainer input{
        margin: 2%;
        border: none;
        border-radius: 2px;
        padding:1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .statsButtons{
        display: inline;
        padding: 0.5% 2%;
        margin: 0 2%;
    }
    .selectedBlue{
        background: var(--blueAVR) !important;
        color:white;
    }
    .selectedGreen{
        background: rgb(8, 167, 8) !important;
        color:white;
    }
    .selectedRed{
        background: red !important;
        color:white;
    }
    .selectedOrange{
        background: rgb(255, 208, 0) !important;
    }
    .leftButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .rightButtonsStats{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bottomButtonsStats{
        text-align: center;
        margin-top: 2vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .chartContainer{
        width:80vw;
        height:75vh;
    }


    .clicked{
        background: var(--blueAVR) !important;
        color:white;
    }
    .testoTempistiche{
        display:inline-block;
        margin-right: 2vw;
    }
    .containerTempistiche{
        margin:1%;
        /* overflow-y: auto; */
    }

    .headContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
        font-weight: 600;
        overflow-wrap: break-word;
    }
    .bodyContainerTempistiche{
        display:grid;
        grid-template-columns: 10% 30% auto;
        font-weight: 300;
        border-radius: 2px;
        padding: 1%;
        font-family: inherit;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
    }
    .containerTempistiche span{
        text-align: center;
    }
    .oreRisposta{
        font-weight: 600;
        color:var(--blueAVR);
    }
    .selectTempistiche{
        width: 54vw;
        margin: 6% 3%;
    }
    .oggettoConversazioneTempistiche{
        overflow-wrap: break-word;

    }
    .avvisoChart{
        /* position: absolute; */
        color: red;
    }
}
.utentiUfficioLista{
    opacity:0;
    padding:0;
    display: none;
    height:0;
    //transition: opacity 0.1s;

}
.utentiUfficioListaAperta{
    padding:1%;
    opacity:1;
    height:100%;
    display: block;
    transition: opacity 0.6s;
}

.utentiUfficioRiga{
    display:grid;
    grid-template-columns: 25% 35% auto ;
    font-weight:200;
    padding:0.5% 0;

}
.utentiUfficioButtons{
    cursor:pointer;
}
.utentiUfficioButtons:hover{
    font-weight: 500;
}

.mostraUtentiButton{

    min-width: -webkit-fit-content;

    min-width: -moz-fit-content;

    min-width: fit-content;
    cursor: pointer;
    margin: 2%;
    border: none;
    border-radius: 5px;
    padding: 1% 2%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    display: inline-block;
    color: var(--blueAVR);

}
.mostraUtentiButton:hover{
    font-weight: 600;
}

@media (min-width: 600px) {
    .singleUser{
        display: grid;
        grid-template-columns: auto 20% 20% 20%;
        padding: 1%;
        padding-left: 2%;
        font-weight: 300;
        margin-top: 1.5vh;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: white;
    }

    .usersButtons{
        cursor:pointer;
        color:var(--blueAVR);
        font-weight: 400;
    }
    .usersButtons:hover{
        text-decoration: underline;
        font-weight: 500;

    }

    .containerGestioneUtenti{
        padding: 0% 2%;
        height: 63vh;
        margin: 0 8%;
        width: 73%;
        padding-top:4vh
    }
    .innerDivContainer{
        overflow-y: auto;
        height: 65vh;
        margin-top: 2vh;
        padding-bottom: 1vh;
    }
    .headGestioneUtenti{
        font-weight: 300;
    }
}


@media (max-width: 600px) {

    .containerGestioneUtenti{
        margin-top: 14vh;
        width: 100vw;
    }
    .headGestioneUtenti{
        padding: 7%;
    }
    .innerDivContainer{
        padding: 4%;
    }
    .singleUser{
        display: grid;
        grid-template-columns: auto 30% 26%;;
        box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 20%);
        border-radius: 2px;
        min-height: 5vh;
        margin-bottom: 2vh;
        padding: 3% 3%;
    }
    .usersButtons{
        /* cursor:pointer; */
        color:var(--blueAVR);
        font-weight: 600;
    }

    .utentiUfficioButtons{
        cursor:pointer;
        color:var(--blueAVR);
        padding:2%;
    }
}

@media (min-width: 600px) {
    .containerCreazioneUffici{
        padding: 0% 2%;
       /*  height: 63vh; */
        margin: 0 8%;
        width: 73%;
    }
    .colonnaUffici{
        
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .innerdivContainerUffici{
        padding-top: 4vh;
        font-weight: 300;
    }
    .inputUffici{
        border: none;
        border-bottom: solid 1px var(--blueAVR);
        caret-color: var(--blueAVR);
        margin: 1vh 0;
        padding: 1vh;
        font-size: inherit;
        background: #f9f9f9;
    }
    #rightCreazioneUfficiComponent{
        text-align: end;
    }
    .colonneCreazioneUffici{
        display:grid;
        grid-template-columns:50% 50%;
        padding:2%;
        align-items: start;
        justify-items: center
    }

    .colonneCreazioneUffici select{
        margin: 3% 0;
    }
    
    .testoPasswordUffici{
        font-size:small;
        margin: 3% 0;
    }
    #createOfficeButton{
        margin: 2vh;
        padding: 1% 3%;
    }
    #creaUtenteUfficioButton{
        padding: 1% 3%;
        /* margin: 2vh; */
    }


}
@media (max-width: 600px) {
    .inputUffici{
        border: none;
        border-bottom: solid 1px var(--blueAVR);
        caret-color: var(--blueAVR);
        margin: 1vh 0;
        padding: 1vh;
        font-size: inherit;
        
    }
    .containerCreazioneUffici{
        margin-top: 14vh;
        width: 100vw;
    }
    .innerdivContainerUffici{
        padding: 5%;
        text-align: center;
    }

    .colonneCreazioneUffici{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6%;
    }
    .colonneCreazioneUffici select{
        width:50vw;
        margin:1.5vh 0;
    }

    #createOfficeButton{
        margin: 2vh;
    }
    #creaUtenteUfficioButton{
        margin: 2vh;
        padding: 1% 3%;
    }

}

.headModelloPage{
    padding-top: 3vh;
    margin-bottom:2%;
    font-weight: 300;
}

.inputRicercaOggetto231{
    border:none;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin: 0 1% 0 1% ;
    height: 5vh;
    caret-color: var(--blueAVR);
    padding-left: 1%;
}

.tableRichieste span{
    overflow-wrap: break-word;
    padding: 2%;
}


.mostraImmaginiSpan{
    cursor: pointer;
    font-size: smaller;
    color:var(--blueAVR);
}
.mostraImmaginiSpan:hover{
    text-decoration: underline;
}
.modalImages{
    position:absolute;
    bottom: 8%;
    right: 5%;
    /* width: 40vw; */
    padding:2%;
    background:white;
    border: 1px solid var(--blueAVR);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    z-index:1;
}
.modalImages a{
    display:block;
    text-decoration: none;
    color: var(--blueAVR);
    position:relative;
}

.container231{
    text-align:center;
    margin-right:5vw;
}

.container231 h2{
    margin: 5vh auto 1vh;
    font-size: x-large;
}

#closeImages{
    font-size: x-large;
    color: var(--blueAVR);
    cursor:pointer;
    position:absolute;
    top:0;
    right:2%;
}
#closeImages:hover{
    font-weight: bold;
}

@media (min-width: 600px) {
    
    .containersVariModello{
        margin: 0 8%;
        padding-top: 2vh;
    }
    .tableRichieste{
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: white;
        font-weight: 300;
        display: grid;
        grid-template-rows:5vh 4vh auto ;
        grid-template-columns: 20% 50% auto;
        margin-top:1%;
        margin-right: 1%;
        padding: 3vh 1vw;
        padding: 2%;
    }
    .containerTablesRichieste{
        width: 67vw;
        height: 43vh;
        overflow-y: auto;
        padding-bottom: 15vh;
    }
    .subjectModello231{
        font-weight: 200;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    .textModello231{
        font-weight: 300;
        grid-column-start: 1;
        grid-column-end: 4;
        overflow-x: scroll;
    }
    #containerSearchModello{
        display:inline;
    }
    #containerDateModello{
        display:inline;

    }
}

@media (max-width: 600px) {

    .containersVariModello{
        margin: 15vh 2vw;
        width: 100vw;
    }
    .divContainerModello{
        padding:5%;
    }
    .headModelloPage {
        margin-bottom: 7%;
    }
    #listaAziendeValue{
        width: auto;
        padding: 3%;
        margin-bottom:4%;
    }
    .inputRicercaOggetto231{
        margin: 2% 3% 3% 0;
        padding: 0% 2%;
    }

    .containerTablesRichieste{
        overflow-y: auto;
        padding-bottom: 10vh;
    }
    .tableRichieste{
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        margin: 7% 1%;
        padding:4% 4%;
        display: grid;
        grid-template-rows: 5vh 5vh auto auto;
        grid-template-columns: 25% 75%;
        
    }
    .mostraImmaginiSpan{
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .subjectModello231{
        font-weight: 200;
        font-size:smaller;
        grid-column-start: 1;
        grid-column-end: 3;
        overflow-wrap: break-word;
        margin-bottom:2%
    }
    .textModello231{
        font-weight: 300;
        grid-column-start: 1;
        grid-column-end: 3;
        overflow-wrap: break-word;
        overflow-x: scroll;
    }

    #containerDateModello{
        display: inline-flex;
        flex-direction: column;

    }
    .modalImages{
        position: fixed;
        bottom: 5%;
        width: 85%;
        padding: 2%;
        background: white;
        border: 1px solid var(--blueAVR);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        z-index: 1;
        font-weight: 300;
    }


}
.divContainerAnonime{
    padding:2% 7%;
}

.containerAnonimePage{
    overflow-y: auto;
    height: 70vh;
    /* padding-top: 3vh; */
}
.headAnonimePage{
    padding-top: 3vh;
    font-weight: 300;
}
.archiviaButton{
    cursor:pointer;
}

.archiviaButtonSegnalazioneAnonima{
    cursor:pointer;
    color:var(--blueAVR);
}
.archiviaButtonSegnalazioneAnonima:hover{
    text-decoration: underline;
}



@media (min-width: 600px) {
    .singleSegnalazioneAnonima{
        display: grid;
        grid-template-columns:  5vw  50vw auto;
        grid-template-rows:  auto auto;
        padding: 1%;
        padding-left: 2%;
        font-weight: 300;
        margin-top: 2.5vh;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: white;
    }
    .textSegnalazioneAnonima{
        padding:2%;
        grid-column: 1/4;
        overflow-x: scroll;
        overflow-y: hidden;
       
    }

    .headAnonyme {
        line-height: 1.2em;
        font-weight: 300;
        width: 55%;
        text-align: center;
        margin: 0 auto;
    }
    .anonymeButtons{
        padding: 1.5% 3%;
        margin-top:7%;
    }
    .anonymBtns .clickHere {
        font-weight: 400;
    }
    
    .anonymBtns span {
        color: var(--blueAVR);
    }
    
    .anonymBtns .clickHere:hover {
        transition: all 0.2s linear;
        font-weight: 500;
    }
}


@media (max-width: 600px) {
    .containersVariAnonime {
        margin-top: 11vh;
        width: 100vw;
        padding-top: 0%;
    }

    .divContainerAnonime{
        padding:5%;
    }
    .containerAnonimePage{
        padding-bottom: 3vh;
    }

    .headAnonimePage {
        padding: 3vh 0;
    }

    .singleSegnalazioneAnonima{
        padding: 1%;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        margin: 7% 0;
        display: grid;
        grid-template-columns:  15vw  auto;
        grid-template-rows:  auto auto auto;
    }

    .textSegnalazioneAnonima{
        font-weight:300;
        grid-column: 1/3;
        padding: 2% 0;
        overflow-x: scroll;
        overflow-y: hidden;
        
    }

    .archiviaButtonSegnalazioneAnonima{
        grid-column: 1/3;
        padding-top:2%;
    }
    .anonime{
        padding: 13% 7%;
        font-size: unset;
    }
    
    .anonymBtns {
        margin: 5%;
        color: var(--blueAVR);
        font-size: 1.3em;
    }

}

.sidebar{
    background-color: white; 
/*     width: 18vw; */
    padding: 7% 3% 7% 7%;
}

.sideMenu{
    /* width: 95%; */
    margin-top:18%;
    margin-left:11%;
}

.sideMenu a{
    font-size:large;
    color: black;
    text-decoration: none;
    font-size:1rem;
}

.sideMenu a:hover{
    color: var(--blueAVR);
    font-weight: 700;
}

.sideMenu .active{
    color: var(--blueAVR);
    font-weight: 700;
}



.privacyDiv a{ 
    font-size: medium;
    font-weight: 400;
    color: black;
    text-decoration: none;
}
.privacyDiv a:hover{
    color: var(--blueAVR);
    font-weight: 700;
}

.sideMenuButtons{
    background-color: #f7f7f7;
    border: none;
    border-radius: 30px;
    color: var(--blueAVR);
    cursor: pointer;
    font-size: medium;
    font-family: inherit;
    font-weight: 600;
    padding: 3% 5%;
}

.sideMenuButtons:hover{
    background: var(--blueAVR);
    color: #ffffff;
}

@media (min-width: 600px){
    #topDivSidebar{
        position: fixed;
    }
    .sideMenu{
        position: fixed;
        margin-top: 25vh;
        margin-left: 3vw;
    }

    #topDivSidebar{
        font-size: x-large;
        display: flex;
        align-items: center;
        color: var(--blueAVR);
        /* font-weight: 800; */
        justify-content: center;
        width: 18%;
        flex-wrap: wrap;
    }
    #titoloSidebar{
        margin-top: 0vh;
        font-size: 2.5rem;
        font-weight: 200;
    }
    
    .bottoniSidebar{
        height: 16vh;
        position: fixed;
        bottom: 7vh;
        width: 18vw;
    }
    .privacyDiv{
        text-align: center;
        /* margin-top: 5vh; */
        width: 17vw;
        padding: 1vh;
        font-size: medium;
        border-top: solid 1px #c9c7c7;
        position: fixed;
        bottom: 1vh;
    }
    .sideMenu p{
        margin-bottom:3vh;
        white-space: nowrap;
    }

}
@media (max-width: 600px) {
    .sidebar{
        width: 90vw;
        position: fixed;
        top: 13vh;
        background-color: var(--blueAVR);
        padding: 0 5vw;
        z-index: 3;
        transition: all 0.8s;
        -webkit-transform: translatex(-115vw);
                transform: translatex(-115vw); /* a -95 è CHIUSA */
        height: -webkit-fill-available;
        display: grid;
        grid-template-rows: 0% auto 15%;
    }
    .open{
        -webkit-transform: translatex(0vw);
                transform: translatex(0vw);
    }
    .sideMenu{
        margin: 5vh 0;
    }
    .sideMenu a{
        color: white;
        font-size:medium;
    }
    .sideMenu p{
        margin: 4vh 2vh;
    }

    .sideMenuButtons{
        font-size:small;
    }
    .bottoniSidebar{
        margin-top: 40vh;
        height: 20vh;
    }

    .privacyDiv {
        text-align: unset;
        font-size: xx-small;
        border-top: solid 1px white;
        padding: 3vh;
    }
    .privacyDiv a {
        font-size: small;
        color: white;
        text-decoration: none;
    }
}
/* 
@media (orientation:landscape) {

    #tappaBuchiLoginLeft{
        display:none;
    }
    #logoPrincipaleLogin{
        display:none;
    }
    .bodyLogin{
        grid-template-columns: 0 100%;
    }
    main{
        grid-template-columns: 0% auto;
    }
    .formInput {
        padding-top: 0;
        font-size: small;
    }

    .infoModalRegistration{
        border: none;
        border-radius: 0;
        inset: unset;
        width: -webkit-fill-available; 
        height: -webkit-fill-available;

        max-height: none;
        max-width: none;
        margin: 0 auto;
        transform: unset;
        padding: 15vh 6%;
        font-size: smaller;
    }
    .infoModalRegistration {
        border: none;
        border-radius: 0;
        inset: unset;
        width: -webkit-fill-available;
        height: -webkit-fill-available;

        max-height: none;
        max-width: none;
        margin: 0 auto;
        transform: unset;
        padding: 3vh 6vw;
        font-size: smaller;
    }
    .bodyRegister{
        text-align: center;
        grid-template-columns:100% 0%;
    }
    #bodyRegisterRight{
        display:none;
    }

    #logoPrincipale{
        display:none;
    }
    #titoloSidebar{
        display:none;
    }
    .containerRegister{
        width: 100vw;
    }
    .bodyRegister{
        width: auto;

    }
    #logoPrincipaleRegister {
        display:none;
    }
    #infoModalOk {
        padding: 2% 5%;
        margin-top:10vh;
    }
    #bodyRegisterLeft {
        padding: 0;
        font-size: small;
    }
    #registerButton {
        font-size: medium;
    }
    .errorMessage {
        color: red;
        font-size: small;
        width: 100%;
    }
    .regexpDescrP{
        padding: 0 2vw;
    }
}
 */
