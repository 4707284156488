
.paginationNumbers{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 20vw;
    width: 78vw;
    bottom: 0;
    height: 6vh;
    background: #f9f9f9;
}

.singleNumber{
    cursor: pointer;
    margin-left:0.7vw;
    margin-right:0.7vw;
    font-size: .9em
}

.singleNumber.active{
    color: #616161;
    font-weight:900;
}

.arrowsPagination{
    cursor: pointer;
    width: 0.5vw;
    margin-top:0.25vh;
}

.paginationNumbersInfo{
    font-weight: 200;
    position: absolute; 
    bottom:3vh;
    right: 10vw;;
}

#arrowLeft2{
    margin-right :1vh;
}
#arrowLeft3{
    margin-right :1vh;
}

#arrowRight1{
    margin-left :1vh;
}
#arrowRight2{
    margin-left :1vh;
}



@media (max-width: 600px) {
    .paginationNumbers{
        width:100vw;
        left: auto;
        bottom: 0vh;
        background: white;
        height: 6vh;
        font-size: medium;
    }
    
    .paginationNumbersInfo {
        display:none; 
    }
    
    #arrowLeft2{
        margin-right :2.5vh;
    }
    #arrowLeft3{
        margin-right :3.5vh;
    }
    #arrowRight1{
        margin-left :3.5vh;
    }
    #arrowRight2{
        margin-left :2.5vh;
    }
    .containerPaginationNumbers{
        white-space: nowrap;
        overflow: auto;
        max-width: 42%;
    }

    .containerPaginationNumbers::-webkit-scrollbar {
        width: 0;
      }
      
}