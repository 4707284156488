

@media (min-width: 600px){
    
    .containerMessaggiSuperadminPage{
        padding: 3% 2%;
        margin: 0 8%;
        width: 73%;
        font-weight: 300;
    }


}

@media (max-width: 600px){

    .containerMessaggiSuperadminPage{
        margin-top: 16vh;
        width: 90vw;
        text-align: center;
        padding: 0 5vw;
    }

    .containerMessaggiSuperadminPage select{    
        width: 50vw;
    }
}
