
@media (min-width: 600px) {
    .containerCreazioneUffici{
        padding: 0% 2%;
       /*  height: 63vh; */
        margin: 0 8%;
        width: 73%;
    }
    .colonnaUffici{
        
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .innerdivContainerUffici{
        padding-top: 4vh;
        font-weight: 300;
    }
    .inputUffici{
        border: none;
        border-bottom: solid 1px var(--blueAVR);
        caret-color: var(--blueAVR);
        margin: 1vh 0;
        padding: 1vh;
        font-size: inherit;
        background: #f9f9f9;
    }
    #rightCreazioneUfficiComponent{
        text-align: end;
    }
    .colonneCreazioneUffici{
        display:grid;
        grid-template-columns:50% 50%;
        padding:2%;
        align-items: start;
        justify-items: center
    }

    .colonneCreazioneUffici select{
        margin: 3% 0;
    }
    
    .testoPasswordUffici{
        font-size:small;
        margin: 3% 0;
    }
    #createOfficeButton{
        margin: 2vh;
        padding: 1% 3%;
    }
    #creaUtenteUfficioButton{
        padding: 1% 3%;
        /* margin: 2vh; */
    }


}
@media (max-width: 600px) {
    .inputUffici{
        border: none;
        border-bottom: solid 1px var(--blueAVR);
        caret-color: var(--blueAVR);
        margin: 1vh 0;
        padding: 1vh;
        font-size: inherit;
        
    }
    .containerCreazioneUffici{
        margin-top: 14vh;
        width: 100vw;
    }
    .innerdivContainerUffici{
        padding: 5%;
        text-align: center;
    }

    .colonneCreazioneUffici{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6%;
    }
    .colonneCreazioneUffici select{
        width:50vw;
        margin:1.5vh 0;
    }

    #createOfficeButton{
        margin: 2vh;
    }
    #creaUtenteUfficioButton{
        margin: 2vh;
        padding: 1% 3%;
    }

}