
.headModelloPage{
    padding-top: 3vh;
    margin-bottom:2%;
    font-weight: 300;
}

.inputRicercaOggetto231{
    border:none;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin: 0 1% 0 1% ;
    height: 5vh;
    caret-color: var(--blueAVR);
    padding-left: 1%;
}

.tableRichieste span{
    overflow-wrap: break-word;
    padding: 2%;
}


.mostraImmaginiSpan{
    cursor: pointer;
    font-size: smaller;
    color:var(--blueAVR);
}
.mostraImmaginiSpan:hover{
    text-decoration: underline;
}
.modalImages{
    position:absolute;
    bottom: 8%;
    right: 5%;
    /* width: 40vw; */
    padding:2%;
    background:white;
    border: 1px solid var(--blueAVR);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    z-index:1;
}
.modalImages a{
    display:block;
    text-decoration: none;
    color: var(--blueAVR);
    position:relative;
}

.container231{
    text-align:center;
    margin-right:5vw;
}

.container231 h2{
    margin: 5vh auto 1vh;
    font-size: x-large;
}

#closeImages{
    font-size: x-large;
    color: var(--blueAVR);
    cursor:pointer;
    position:absolute;
    top:0;
    right:2%;
}
#closeImages:hover{
    font-weight: bold;
}

@media (min-width: 600px) {
    
    .containersVariModello{
        margin: 0 8%;
        padding-top: 2vh;
    }
    .tableRichieste{
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: white;
        font-weight: 300;
        display: grid;
        grid-template-rows:5vh 4vh auto ;
        grid-template-columns: 20% 50% auto;
        margin-top:1%;
        margin-right: 1%;
        padding: 3vh 1vw;
        padding: 2%;
    }
    .containerTablesRichieste{
        width: 67vw;
        height: 43vh;
        overflow-y: auto;
        padding-bottom: 15vh;
    }
    .subjectModello231{
        font-weight: 200;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    .textModello231{
        font-weight: 300;
        grid-column-start: 1;
        grid-column-end: 4;
        overflow-x: scroll;
    }
    #containerSearchModello{
        display:inline;
    }
    #containerDateModello{
        display:inline;

    }
}

@media (max-width: 600px) {

    .containersVariModello{
        margin: 15vh 2vw;
        width: 100vw;
    }
    .divContainerModello{
        padding:5%;
    }
    .headModelloPage {
        margin-bottom: 7%;
    }
    #listaAziendeValue{
        width: auto;
        padding: 3%;
        margin-bottom:4%;
    }
    .inputRicercaOggetto231{
        margin: 2% 3% 3% 0;
        padding: 0% 2%;
    }

    .containerTablesRichieste{
        overflow-y: auto;
        padding-bottom: 10vh;
    }
    .tableRichieste{
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        margin: 7% 1%;
        padding:4% 4%;
        display: grid;
        grid-template-rows: 5vh 5vh auto auto;
        grid-template-columns: 25% 75%;
        
    }
    .mostraImmaginiSpan{
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .subjectModello231{
        font-weight: 200;
        font-size:smaller;
        grid-column-start: 1;
        grid-column-end: 3;
        overflow-wrap: break-word;
        margin-bottom:2%
    }
    .textModello231{
        font-weight: 300;
        grid-column-start: 1;
        grid-column-end: 3;
        overflow-wrap: break-word;
        overflow-x: scroll;
    }

    #containerDateModello{
        display: inline-flex;
        flex-direction: column;

    }
    .modalImages{
        position: fixed;
        bottom: 5%;
        width: 85%;
        padding: 2%;
        background: white;
        border: 1px solid var(--blueAVR);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        z-index: 1;
        font-weight: 300;
    }


}