 .errorText{
    color:red;
    background-color:white;
    font-size:small;
    padding:1%;
    width:max-content;
    position:absolute;
    right:-2vw;
 }

@media (min-width: 600px) {
    #containerChatOuter{
        width: 60vw;
        padding: 1% 0;
    }
    #subjectChat{
        text-align: left;
        margin-left: 3vw;
    }
    #consensusChatPage{
        margin-left: 3vw;
    }

    #containerChatOuter textarea{
        width:85%;
        font-family: inherit;
        caret-color: var(--blueAVR);
        border: none;
        border-radius: 5px;
        padding: 1vh 2vh;
        height: 4vh !important;
        resize: none;
        background: #f9f9f9;

    }
    .storeMessageButton{
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
    }
    .storeMessageButton:hover{
        color: var(--blueAVR);
        font-weight: 700;
    }

    
    #containerChatInner{
        height:53vh;
        display: flex;
        flex-direction: column;
        padding:2% 4% 0 4%;
        overflow: auto;
        margin-top:2vh;
    }
    #containerChatInnerSuperadmin{
        height:63vh;
        display: flex;
        flex-direction: column;
        padding:2% 4% 0 4%;
        overflow: auto;
        margin-top:2vh;
    }

    #replyContainer{
        /* width: 60vw; */
        text-align: justify;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 0.5%;
        margin: 2vh auto;
        display: flex;
        justify-content: space-between;
    }
    
    .messageInbox{
        height: fit-content;
        padding:1%;
        display: flex;
        justify-content: flex-start;
    }
    .messageSent{
        height: fit-content;
        padding:1%;
        display: flex;
        justify-content: flex-end;
    }
    .testoLui{
        background: rgb(140 204 220); 
        border-radius:5px;
        min-width:20vw;
        max-width:28vw;
        height: fit-content;
        padding: 1%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }
    .testoIo{
        background: rgb(221 221 221);
        border-radius:5px;
        min-width:20vw;
        max-width:28vw;
        height: fit-content;
        padding: 1%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }
    .replyChatButton{
        width: 3vw;
        height: 3vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        display:inline-block;   
        margin:0; 

    }
    .replyChatButtonAddFiles{
        width: 3vw;
        height: 3vw;
        border: none;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        display:flex;
        color: white;
        align-items: center;
        justify-content: center;
        position:relative;
    }
    .replyChatButtonAddFiles label{
        cursor: pointer;
        padding:20%;
    }

    .fileSelezionatiText{
        position:absolute;
        bottom:-3.5vh;
        
        color: black;
        font-size:small;
    }
    .dataEora{
        margin-top:1%;
        font-size: x-small;
        text-align: right;
    }
    .backButton{
        /* display:none; */
        width: 35px;
        height: 35px;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        transform: rotateY(3.142rad);
        margin-left: 3vw;
    }
    #headChatPage{
        display: grid;
        grid-template-columns: 15% 50% auto;
        align-items: center;
        width: 60vw;
    }
    .archiviaButton{
        text-align: center;
        max-width: max-content;
        padding:2% 3%;
    }
    .immagineApertaDiv{
        opacity: 1;
        z-index: 5;
        transition: all 0.5s;
        position: fixed;
        top: 0em;
        right: 1em;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);
    }
    .immagineApertaDiv img{
        cursor: pointer;
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
    
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
}

@media (max-width: 600px) {
    .fileSelezionatiText{
        position: absolute;
        top: 7vh;
        right: 2vw;
        color: black;
        font-size: small;
    }
    #chatTitle{
        font-size: large;
        text-decoration: none;
        text-align: center;
        padding: 1% 3%;
    }
    #containerChatOuter {
        width:100vw;
    }
    #containerChatInner{
        max-height:50vh;
        display: flex;
        flex-direction: column;
        padding:2% 4% 0 4%;
        overflow: auto;
        margin-bottom: 12vh;
    }
    .messageSent{ 
        display: flex;
        justify-content: flex-end;
        padding:1%;
    }
    .messageInbox{
        display: flex;
        justify-content: flex-start;
        padding:1%;
    }
    .testoLui{
        background: rgb(140 204 220); 
        border-radius:5px;
        min-width: 30vw;
        max-width: 65vw;
        height: fit-content;
        padding: 2%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }

    .testoIo{
        background: rgb(231 231 231 / 52%);
        border-radius:5px;
        min-width: 30vw;
        max-width: 65vw;
        height: fit-content;
        padding: 2%;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: smaller;
    }
    #subjectChat{

        width: auto;
        margin-top: 0vh;
        font-size: small;
        padding: 1vh 5vw;
        display: block;
    }
    #replyContainer{
        display: flex;
        justify-content: space-between;
        width: 95vw;
        text-align: justify;
        border: 1px solid rgb(160, 160, 160);
        border-radius: 3px;
        padding: 0.5%;
        margin: 1vh;
        position: fixed;
        bottom: 0vh;
        background: white;
    }
    #replyContainer textarea{
        width:70%;
        font-family: inherit;
        caret-color: var(--blueAVR);
        border: none;
        border-radius: 5px;
        padding: 1vh 2vh;
        height: 7vh !important; 
        resize: none;
    }
    .replyChatButton{
        width: 10vw;
        height: 10vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        margin: 0 1%;
    }
    .replyChatButtonAddFiles {
        width: 10vw;
        height: 10vw;
        border: none;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        display: flex;
        color: white;
        align-items: center;
        margin: 0 1%;
        justify-content: center;
        /* position: relative; */
    }
    .dataEora{
        margin-top:1%;
        font-size: x-small;
        text-align: right;
    }

    .backButton{
        width: 10vw;
        height: 10vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: var(--blueAVR);
        border-radius: 100%;
        color: white;
        transform: rotateY(3.142rad);
        /* position:absolute;
        left: 5vw; */
    }
    #headChatPage{
        /* text-align: center;
        width: 90vw; */
        width: 100vw;
        /* display: flex; */
        align-items: center;
        justify-content: flex-end;
    }
    #consensusChatPage{
        width: auto;
        margin-top: 0vh;
        font-size: small;
        padding: 1vh 5vw;
        display: block;
    }
          
    .immagineApertaDiv{
        opacity: 1;
        z-index: 5;
        transition: all 0.5s;
        position: fixed;
        top: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);

    }
    .chiusa{
        transition: all 0.5s;
        opacity:0;
        visibility:hidden;
    }
    .immagineApertaDiv img{
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
}
