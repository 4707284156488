


main{
    display: grid;
    grid-template-columns: 19vw auto;

}

.Appp2{
    margin-left:1vw;
    /* height: 100vh; */
    background: #f9f9f9;    
}

.containRight{
    display: grid;
    grid-template-rows: 15% 85% ;
}
.containersVari{
    padding:0% 2% 4% ;
   /*  height:63vh; */
    margin: 0 8%;
    width: 73%;
}

h1{
    margin: 3% 0 3% 5%;
}

/* Header */
.header2{
    height: 12vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.imgContUser{
    background-color: var(--blueAVR);
    border-radius: 100%;
    width: 3vw;
    height: 3vw;
    margin-right: 3%;
    cursor: pointer;
    color: white;
    font-size: x-large;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 30px;
    min-width: 30px;
    
}
.imgContMod{
    background-color: var(--blueAVR);
    border-radius: 100%;
    width: 3vw;
    height: 3vw;
    margin-right: 3%;
    cursor: pointer;
    color: white;
    font-size: x-large;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 35px;
    min-width: 35px;
    margin-bottom: 0.3vh;
}


#logoPrincipale{
    min-width: 50px;
    max-width: 60px;
    margin-top: 2vh;
    transform: rotateY(180deg);
}


#iconBell{
    color: black;
    width: 26px;
    height: 31px;
    margin-bottom: 0.5vw;
    position: absolute;
    top:5px;
    left:0;
}

#iconBellCircle{
    width:10px;
    position: absolute;
    left:21px;
    top:0;
}

#menuBars{
    display:none;
}
#bellContainer{
    position:relative;
    width:60px;
    height:40px;
}
#logoPrincipale2{
    display:none;
}



.anteprimaMessaggio{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 48vw;
}

/* .darkModeButton{
    font-size: x-large;
    padding: 0.3% 2%;
    cursor:pointer;
} */

@media (min-width: 600px) {
    .confermaEliminazioneBg{
        z-index:2;
        position: absolute;
        top:0;
        width:-webkit-fill-available;
        height:-webkit-fill-available;
        background-color: rgba(255, 255, 255, 0);
        backdrop-filter: blur(4px);
    }
    .confermaEliminazioneDiv{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 2%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 40vw;
        max-width: 50vw;
        max-height: 50vh;
        text-align:center; 
    }

    #modaleNotificheHeader{
        position: absolute;
        inset: 22vh auto auto 78vw !important;
        border: 2px solid rgb(0, 119, 188)  !important;
        background: rgb(0, 119, 188)  !important;
        border-radius: 7px;
        outline: none;
        padding: 1.5vw  !important;
        color: white;
        transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px ;
        min-width: 18vw;
        max-width: 36vw;
        min-height: 8vh;
        max-height: 34vh;
        overflow-y:auto;
    }
    
    #modalMenuHeader{
        position: absolute;
      /*   right: 2vw;
        top: 15vh; */
        inset: 14% 40px 40px auto !important;
        border: 1px solid rgb(204, 204, 204)  !important;
        background: var(--blueAVR)  !important;
        border-radius: 5px;
        outline: none;
        padding: 2%  !important;
        height: fit-content;
        box-shadow: rgb(193 213 226) 5px 5px 15px;
        color: white;
    }

    #modalMenuHeader p{
        padding:0.5vw;
    }

    .eliminaAccountButton{
        margin:3vh auto;
        cursor:pointer;
    }
    .eliminaAccountButton:hover{
        font-weight: bold;
    }
    .esciButton{
        text-decoration:none;
        cursor:pointer;

    }

    .esciButton:hover{
        font-weight: bold;

    }



}

@media (max-width: 600px) {
    .Appp2{
        margin-left:0;
    }
    main{
        grid-template-columns: 0% auto;
    }
    .containerLogin{
        margin-left: 0%;
    }
    .bodyLogin{
        width: 100vw;
        font-size: smaller;
    }
    .containersVari{
        margin-top: 14vh;
    }
    .containerRegister{
        width: 100vw;
    }
    .bodyRegister{
        width: auto;
        /* padding: 5%; */
    }
    #logoPrincipale{
        display:none;
    }
    #titoloSidebar{
        display:none;
    }
    .header2{
        width: auto;
        padding-bottom: 1vh;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(197, 197, 197);
        padding-top: 1vh;
        position: fixed;
        background: white;
        z-index: 1;
        height: 11vh;
        top:0;
    }
    #menuBars{
        display:block;
    }

    #logoPrincipale2{
        display:block;
        margin-left:30vw;
        /* margin-top:1%; */
    }
    #bellContainer{
        width: 10px;
        height: 25px;
    }
    #iconBell{
        width: 20px;
        height: 25px;
        top: 0;
    }
    #iconBellCircle{
        left: 16px;
        top: -5px;
    }
    .imgContMod{
        width: 11vw;
        height: 11vw;
        font-size:large;
    }
    .imgContUser{
        width: 30px;
        height: 30px;
        font-size:large;
    }

    #modalMenuHeader{
        padding: 7% !important;
        inset:auto;
        top:14% !important;
        left:44% !important;
        width: 40vw;
        height: fit-content;
        color: white;

        border: 1px solid rgb(204, 204, 204)  !important;
        background: var(--blueAVR)  !important;
        border-radius: 5px;
        outline: none;
    }
    #modalMenuHeader h4{
        margin:5% 0;
        font-size:large;

    }
    .anteprimaMessaggio{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 48vw;
    }
    #modaleNotificheHeader{    
        min-width: 51vw;
        max-width:75vw;
        height:fit-content;
        padding:5% !important;
        border: 2px solid rgb(0, 119, 188)  !important;
        background: rgb(0, 119, 188)  !important;
        border-radius: 7px;
        color: white;
        box-shadow: rgb(5 78 125) 2px 2px 7px ;
        outline: none;
        overflow-y:auto; 
        position: sticky;
        top: 15vh  !important;
        left: 16vw  !important;
        z-index:100;
    }
    .esciButton{
        text-decoration:none;
    }
    .esciButton  h3{
        text-decoration:none;
        cursor:pointer;
        font-size:large;
        font-weight:bold;
        margin:3% 0;
    }
    .eliminaAccountButton{
        margin:8% 0;
        font-size:large;
        font-weight:bold;

    }

    .confermaEliminazioneBg{
        z-index:2;
        position: absolute;
        top:0;
        width:-webkit-fill-available;
        height:100vh;
        background-color: rgba(255, 255, 255, 0);
        backdrop-filter: blur(4px);
    }
    .confermaEliminazioneDiv{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 10%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 60vw;
        max-width: 70vw;
        max-height: 70vh;
        text-align:center; 
    }
    .confermaEliminazioneDiv select{
        width:50vw;
    }

}
