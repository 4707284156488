@media (min-width: 600px){
    .confermaEliminazioneBgModal{
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background-color: rgba(255, 255, 255, 0);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
    }
    
    .confermaEliminazioneDivModal{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 2%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 40vw;
        max-width: 50vw;
        max-height: 50vh;
        text-align: center;
    
    }


}


@media (max-width: 600px){
    .confermaEliminazioneBgModal{
        z-index:2;
        position: absolute;
        top:0;
        width:-webkit-fill-available;
        height:100vh;
        background-color: rgba(255, 255, 255, 0);
        backdrop-filter: blur(4px);
    }
    .confermaEliminazioneDivModal{
        position: absolute;
        inset: 50% auto auto 50%;
        /* border: 2px solid rgb(0, 119, 188); */
        background: rgb(255, 255, 255);
        border-radius: 0px;
        outline: none;
        padding: 10%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        box-shadow: rgb(5 78 125) 2px 2px 7px;
        min-width: 60vw;
        max-width: 70vw;
        max-height: 70vh;
        text-align:center; 
    }
}