.divContainerAnonime{
    padding:2% 7%;
}

.containerAnonimePage{
    overflow-y: auto;
    height: 70vh;
    /* padding-top: 3vh; */
}
.headAnonimePage{
    padding-top: 3vh;
    font-weight: 300;
}
.archiviaButton{
    cursor:pointer;
}

.archiviaButtonSegnalazioneAnonima{
    cursor:pointer;
    color:var(--blueAVR);
}
.archiviaButtonSegnalazioneAnonima:hover{
    text-decoration: underline;
}



@media (min-width: 600px) {
    .singleSegnalazioneAnonima{
        display: grid;
        grid-template-columns:  5vw  50vw auto;
        grid-template-rows:  auto auto;
        padding: 1%;
        padding-left: 2%;
        font-weight: 300;
        margin-top: 2.5vh;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: white;
    }
    .textSegnalazioneAnonima{
        padding:2%;
        grid-column: 1/4;
        overflow-x: scroll;
        overflow-y: hidden;
       
    }

    .headAnonyme {
        line-height: 1.2em;
        font-weight: 300;
        width: 55%;
        text-align: center;
        margin: 0 auto;
    }
    .anonymeButtons{
        padding: 1.5% 3%;
        margin-top:7%;
    }
    .anonymBtns .clickHere {
        font-weight: 400;
    }
    
    .anonymBtns span {
        color: var(--blueAVR);
    }
    
    .anonymBtns .clickHere:hover {
        transition: all 0.2s linear;
        font-weight: 500;
    }
}


@media (max-width: 600px) {
    .containersVariAnonime {
        margin-top: 11vh;
        width: 100vw;
        padding-top: 0%;
    }

    .divContainerAnonime{
        padding:5%;
    }
    .containerAnonimePage{
        padding-bottom: 3vh;
    }

    .headAnonimePage {
        padding: 3vh 0;
    }

    .singleSegnalazioneAnonima{
        padding: 1%;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        margin: 7% 0;
        display: grid;
        grid-template-columns:  15vw  auto;
        grid-template-rows:  auto auto auto;
    }

    .textSegnalazioneAnonima{
        font-weight:300;
        grid-column: 1/3;
        padding: 2% 0;
        overflow-x: scroll;
        overflow-y: hidden;
        
    }

    .archiviaButtonSegnalazioneAnonima{
        grid-column: 1/3;
        padding-top:2%;
    }
    .anonime{
        padding: 13% 7%;
        font-size: unset;
    }
    
    .anonymBtns {
        margin: 5%;
        color: var(--blueAVR);
        font-size: 1.3em;
    }

}