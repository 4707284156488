
.containerNewMsg {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.headNewMsg {
    display: flex;
    color: var(--blueAVR);
    width: 76vw;
}
.headNewMsg h2{
    font-weight: 400;
    margin-top: 0;
}

.bodyNewMsg {
    width: 45vw;
    padding: 0 3%;
    border-radius: 15px;
    text-align: center;
}

.inputTopic {
    border: none;
    border-radius: 20px;
    min-height: 25px;
    width: 16vw;
    background-color: rgb(241, 241, 241);
    padding: 1%;
    font-family: inherit;
}

select {
    border: none;
    border-radius: 20px;
    min-height: 25px;
    width: 16vw;
    background-color: rgb(241, 241, 241);
    padding: 1%;
    font-family: inherit;
}

select:focus-visible {
    outline: none;
    border: none;
}

option:focus-visible {
    outline: none;
}

.inputTopic:focus-visible {
    outline: none;
}

textarea:focus-visible {
    outline: none;
}
#textareaSubject{
    border: none;
    border-radius: 2px;
    min-width: 25vw;
    height: 3vh;
    padding: 2%;
    font-family: inherit;
    caret-color: var(--blueAVR);
    text-align: justify;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}


.buttonWrap {
    display: flex;
    margin-top: 8%;
    margin-bottom: 3%;
    justify-content: space-around;
}

h4 {
    margin:  1%;
    font-weight: bold;
}

.filterBy {
    position: absolute;
    top: 22%;
    left: 64%;
}

#faInfoConsensus {
    color: var(--blueAVR);
    cursor: help;
    width: 2vw;
    height: 3vh;
    /*  position: absolute;
    right: 25vw; */
}

.infoConsensusTooltip {
    background-color: rgb(245, 245, 245);
    padding: 1%;
    border-radius: 5px;
    position: absolute;
    width: 20vw;
    right: 0vw;
    bottom: 19vh;
    opacity: 0;
    transition: all 0.5s;
    text-align: justify;
    font-size: small;
    font-weight: 400;
    max-width: 23vw;

}

#faInfoConsensus:hover+.infoConsensusTooltip {
    opacity: 1;
    right: 3vw;

}

#checkboxConsensus {
    visibility: hidden;
}

.main {
    display: block;
    position: relative;
    padding-left: 45px;
    cursor: pointer;
}

/* Creating a custom checkbox based on demand */
.geekmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 5px;
    background-color: rgb(241, 241, 241); 
}

/* Specify the background color to be shown when hovering over checkbox */
.main:hover input~.geekmark {
    background-color: rgb(245, 245, 245);
}

/* Specify the background color to be shown when checkbox is active */
/* .main input:active~.geekmark {
    background-color: red;
} */

/* Specify the background color to be shown when checkbox is checked */
.main input:checked~.geekmark {
    background-color: var(--blueAVR);
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Display checkmark when checked */
.main input:checked~.geekmark:after {
    display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and showing only two border to make it look like a tickmark */
.main .geekmark:after {
    left: 7.5px;
    bottom: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
#consensusNewMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh;
}


.labelAggiungi{
    margin:1%;
    cursor:pointer;
    display:inline-block;
}

.labelAggiungi:hover{
    font-weight:bold;
    color: var(--blueAVR);
}

@media (min-width: 600px) {
    #newMessTitle{
        margin: 5vh auto 1vh;
        font-size:x-large;
    }
    #textareaTesto {
        border: none;
        border-radius: 2px;
        min-width: 30vw;
        min-height: 10vh;
        padding: 2%;
        font-family: inherit;
        caret-color: var(--blueAVR);
        text-align: justify;
        
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    #newMsgButtonsContainer .confermaDelete{
        margin:1vh;
    }

}
@media (max-width: 600px) {
    .filterBy {
        position: static;
        font-size: small;
        width: 90vw;
        text-align: center;
        margin-top: 2vh;
        margin-left: 5vw;
    }
    .filterBy label{
        font-size: inherit;
        display: inline;
        font-weight: 200 !important;
    }

    .filter{
        width: 20vw;
    }
    .containerNewMsg{
        width:100vw;
    }
    .headNewMsg h2{
        width: 100vw;
        font-size: large;
        text-decoration: none;
        margin-top: 4vh;
        text-align: center;
    }
    .bodyNewMsg {
        width:100vw;
    }
    .bodyNewMsg h4{
        font-weight: 200;
    }
    .bodyNewMsg select{
        margin-bottom:2vh;
        margin-top:2vh;
        width:70vw;
    }
    .bodyNewMessage .errorP{
        margin: 0;
    }
    #textareaSubject{
        margin-top:2vh;
        margin-bottom:1vh;
        width:70vw;
        height:4vh;
    }
    #textareaTesto {
        border: none;
        border-radius: 2px;
        padding:2%;
        font-family: inherit;
        caret-color:var(--blueAVR);
        text-align: justify;
        box-shadow:0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        /* transition: all 0.3s cubic-bezier(.25,.8,.25,1);  */
        margin-top:2vh;
        width:70vw;
        min-height:25vh; 
    }
    #consensusNewMsg{
        font-size: small;
    }
    #consensusNewMsg label{
        padding-left: 9vw;
    }
    #faInfoConsensus {
        display:none;
    }
    .infoConsensusTooltip {
        display:none;
    }
    #newMsgButtonsContainer .confermaDelete{
        margin-top:2vh;
    }
}