.singleNotice{
    cursor:pointer;
    display:grid;
    grid-template-columns: auto 25% 20%;
    padding-left: 2%;
    font-weight: 300;
    margin-bottom: 2.5vh;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background:white;
}

.singleNotice:hover{
    color:var(--blueAVR);
    /* font-weight:bold; */
    border-radius: 9px;
    border-radius: 2px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.dataNotice{
    padding: 1.5em;
    text-align: center;
    font-size: .8em;
}

.ratingNotice{
    padding: 1.5em;
    text-align: center;
    font-size: .8em;
}

.headListaNotices{
    display:grid;
    grid-template-columns: auto 15% 28%;
    font-weight:500;
    margin-left:2%;
    text-align: center;
}

#starIcon{
    cursor: help;
}
.tooltipStarsRating{
    background-color: rgb(245, 245, 245);
    padding: 0.7%;
    border-radius: 5px;
    position: absolute;
    width: 12vw;
    text-align: center;
    left: 52%;
    top: 28vh;
    opacity:0;
    font-weight:400;
    font-size: small;
}

#starIcon:hover + .tooltipStarsRating{
    opacity:1;
}

#clockIcon{
    margin-left:11vw;
    cursor: help;
}
#clockIcon:hover + .tooltipClock{
    opacity:1;
}

.tooltipClock{
    background-color: rgb(245, 245, 245);
    padding: 0.5%;
    border-radius: 5px;
    position: absolute;
    width: 9vw;
    text-align: center;
    left: 71vw;
    top: 24vh;
    opacity:0;
    font-weight:400;
    font-size: small;
}

#topicIcon{
    cursor: help;
}

#topicIcon:hover + .tooltipTopic{
    opacity: 1;
}

.tooltipTopic{
    background-color: rgb(245, 245, 245);
    padding: 0.5%;
    border-radius: 5px;
    position: absolute;
    width: 13vw;
    text-align: center;
    left: 29%;
    top: 30vh;
    opacity:0;
    font-size: small;
    font-weight:400;
}

.modalOpenNotice{
    position: absolute;
    inset: 50% auto auto 50%;
    /* border: 2px solid rgb(0, 119, 188); */
    background: rgb(255, 255, 255);
    border-radius: 0px;
    outline: none;
    padding: 2%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    min-width: 40vw;
    max-width: 50vw;
    max-height: 50vh;
}

.modalConfirmationDelete{
    position: absolute;
    inset: 50% auto auto 50% !important;
    /* border: 2px solid rgb(0, 119, 188) !important; */
    background: rgb(255, 255, 255);
    border-radius: 0px !important;
    outline: none;
    transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
    min-width: 30vw;
    text-align: center;
    padding: 2%;
}

.confermaDelete{
    margin-top:5%;
}
.iconeCardNotices{
    display:none;
}
.topicNotice{
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 37vw;
    padding: 4% 0 2%;
}
.textNotice{
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 37vw;
    font-size: smaller;
    padding: 2% 0;
}

@media (max-width: 600px) {
    .listaNot{
        margin-top: 0vh;
        height: fit-content;
        font-size: small;
        width: 94vw;
        padding: 0 3vw;
        padding-bottom: 10vw;
    }
    .tooltipTopic{
        display:none;
    }
    .textNotice{
        display:none;
    }
    .tooltipStarsRating{
        display:none;
    }
    .tooltipClock{
        display:none;
    }
    .headListaNotices{
        display: none;
    }

    .singleNotice{
    display: block;
    box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 20%);
    border-radius: 2px;
    min-height: 11vh;
    margin-bottom: 2vh;
    }
    
    .singleNotice:hover{
        border-radius: 0px;
    }
    #clockIcon{
        margin-left: 7vw;
        margin-top: 0.5vh; 
    }
    .iconeCardNotices{
        display:inline-block;
        margin-right: 3vw;
    }
    .topicNotice{
        font-size: larger;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85vw;
        margin-left: 3vw;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }
    .ratingNotice{
        text-align: center;
        width: 45vw;
        display: inline-block;
        padding: 0;
    }
    .dataNotice {
        text-align: center;
        width: 45vw;
        display: inline-block;
        padding: 0;
    }
    
    .modalOpenNotice{
        font-size: smaller;
        inset: unset;
        margin: 0 auto;
        transform: unset;
        padding: 4%;
        border:none;
        border-radius: 0;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        max-height: none;
        max-width: none;
        margin: 0 auto;
    }

    .modalConfirmationDelete{
        inset: unset !important;
        padding: 4%;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        max-height: none;
        max-width: none;
        border:none !important;
        border-radius: 0 !important;
        transform: unset;
        padding: 6%;
        padding-top: 33vh;
        
    }
    .confermaDelete{
        padding: 2% 4% !important;
        font-size: small;
        font-weight: 400;
        margin-top: 8%;
        
    }
}
