
.loginPage {
    text-align: center;
}
label {
    display: block;
}
.inputLogin {
    border: none;
    border-bottom: solid 1px var(--blueAVR);
    caret-color: var(--blueAVR);
    padding-left: 1%;
    margin-bottom: 2vh;
}
input:focus{
    outline: none;
}
.buttonSignin {
    cursor: pointer;
    padding: 1vh 2vw;
    font-size: large;
    font-family: inherit;
    font-weight: 400;
    background: var(--blueAVR);
    color: white;
    background: var(--blueAVR);
    border: solid 3px var(--blueAVR);
    border-radius: 25px;
}
.buttonSignin:active{
    color:var(--blueAVR);
    background: #f7f7f7;
    border: solid 3px  #f7f7f7;
}
.clickHere {
    display: inline;
    color: var(--blueAVR);
    cursor: pointer;
}
.clickHere:hover {
    font-weight: bold;
}

#errorMessageLogin{
    color:red;
    margin:2%
}
#infoModalOk{
    padding: 1% 3%;
}
#usernameLabel{
    display:inline-block;
    margin:0 auto;
    margin-left:2%;
    color: var(--blueAVR);
    font-weight: 600;
}
.showPasswordIcon{
    /* min-width:13px; */
    width: 15px !important;
    cursor:pointer;
    color: var(--blueAVR);
}

#registerButton{
   /*  margin:0 0 5vh 0 !important; */
   margin-bottom:2%;
}
.insertImages{
    /* margin-top:2%; */
}

.infoModalRegistration{
    position: absolute;
    inset: 51% auto auto 50%;
    background: rgb(255, 255, 255);
    outline: none;
    padding: 3%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: rgb(5 78 125) 2px 2px 7px;
   /*  max-width: 30vw; */
   max-width: 50vw;
    min-height: 20vh;
    text-align: center;
    font-weight: 300;
}
.textAreaAnonyme{
    border: none;
    border-radius: 2px;
    min-width: 29vw;
    height: 8vh;
    padding: 2%;
    font-family: inherit;
    caret-color: var(--blueAVR);
    text-align: justify;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}




.sceltaUfficioDiv{
    display:flex; 
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.sceltaUfficioDiv select{
    margin:5vh 0;
}
    
#accediSAMLButton{
    font-size: small;
    padding: 0.5rem;
    border-radius: 20px;
    margin-top:1.5rem;
}

@media (min-width:600px) {

    #bodyLoginRight{
        height: 100vh;
        width: 66vw;
        position: absolute;
        transition: all 1s ease-out;
        text-align: center;
    }
    .left2 {
        left: 0;
    }
    .right2{
        left: 34vw;
    }
    #bodyRegisterLeft{
        text-align: center;
        padding: 14vh 7vw;
    }
    .errorMessage{
        color:red;
        font-size: medium;
        margin: 1%;
    }
    .registerButtons{
        padding: 1.5% 2% !important;
    }
    .regexpDescrP{
        padding: 0 11vw;
        margin:2%;
    }

    #bodyLoginLeft {
        background: var(--blueAVR);
        width: 34vw;
        height: 100vh;
        position: absolute;
        transition: all 1s ease-out;
        z-index:1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .left {
        left: 0;

        display: flex;
        flex-direction: column;
        justify-items: center;
    }
    .left img{
        transform: rotateY(0deg);
        transition: all 0.5s ease-in;
    }

    .left img.logoAvr{
        transform: rotateY(0deg);
        transition: all 0.5s ease-in;

        width: 100px;
    }
    
    .right{
        left: 66vw;
        display: flex;
        flex-direction: column;
        justify-items: center;
    }

    .right img{
        transform: rotateY(180deg);
        transition: all 0.5s ease-in;
    }
    .right img.logoAvr{
        transform: rotateY(0deg);
        transition: all 0.5s ease-in;

        width: 100px;
    }

    #logoPrincipaleLogin {
        /* position: absolute;
        top: 22vh;
        left: 6vw; */
        max-width: 77%;
    }
    .formInput {
        background: white;
        padding-top: 15vh;
        /* margin-left: 15vw; */
    }
    .inputLogin {
        font-size:inherit;
    }
    main{
        height:100vh;
    }

    .imgMobile{
        display: none;
    }

    .titoloLogin {
        display: block;
    }

    .titoloRegister {
        display: block;
    }
 
    .privacyLegalHomePage a{
        text-decoration: none;
        color: var(--blueAVR);
        position:absolute;
        bottom:2vh;
        font-size: 0.8em;
    }
}

@media (max-width:600px) {
    #bodyLoginRight{
        text-align: center;
    }
    .infoModalTitle{
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-size: x-large;
    }
    .errorMessage{
        color:red;
        font-size:small;
        width: 100vw;
    }
    .regexpDescrP{
        padding: 3vw;
        /* margin-top: 7vh; */
        font-size: small;

    }
    #bodyRegisterLeft{
        /* font-size: smaller; */
        text-align: center;
        padding-top: 10vh;
    }

    #logoPrincipaleLogin {
        display: none;
    }
    .formInput {
        background: white;
        padding-top: 10vh;
        width: 100vw;
        /* font-size:small; */
    }
    .titoloLogin {
        text-align: center;
        width: 100vw;
        text-decoration: none;
    }
    #accediButton {
        margin-top: 5vh;
        width: 40vw;
        font-weight: initial;
    }
    .buttonSignin {
        padding: 1vh 3vw;
    }
    .infoModalRegistration{
        border: none;
        border-radius: 0;
        font-size: small;
        inset: unset;
        /* width: -webkit-fill-available;  */
        height:100vh;
        max-height: none;
        max-width: none;
        margin: 0 auto;
        transform: unset;
        padding: 5% 7%;
    }
    .first{
        font-size: medium;
       /*  width: 76vw; */
        padding-top: 10vh;
    }

    .first h2{
        margin-bottom: 10vh;
    }

    .infoModalRegistration button {
        font-size: medium;
        margin: 1vh;
        padding: 1.5% 2%;
    }
    #registerButton {
        margin: 0 0 3vh 0 !important;
        padding: 1vh 4vw;
    }
    #infoModalOk{
        min-width: 32vw;
        margin-top: 5vh;
    }
    .showPasswordIcon{
        right: 25% !important;
    }
    .clickHere {
        font-size: smaller;
    }
    .headAnonyme{
        padding: 0 5%;    
        font-weight: 300;
    }
    .textAreaAnonyme{
        min-width: 75vw;
        height: 15vh;
    }
    .selectModello231{
        width:55vw;
    }
    .imgMobile{
        display: block;
    }

    .imgMobile img{
        width: 10em;
        margin: 0 0 3em;
    }

    .titoloLogin {
        display: none;
    }

    .titoloRegister {
        display: none;
    }

    .sceltaUfficioDiv{
        margin: 0 7vw ;
    }
    .sceltaUfficioDiv select{
        width:50vw;
    }
    .privacyLegalHomePage {
        margin:5%;
    }
    .privacyLegalHomePage a{
        text-decoration: none;
        color: var(--blueAVR);
        font-size: 1em;
    }

}


