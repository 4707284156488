
.whiteButtons{
    cursor: pointer;
    padding: 0.5% 1%;

    font-size: medium;
    font-weight: 500;
    font-family: inherit;
    background: #f9f9f9;
    border: solid 3px #f9f9f9;
    /* border: solid 2px var(--blueAVR); */
    border-radius: 25px;
    color:var(--blueAVR);

    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.whiteButtons:active{
    color: white;
    background: var(--blueAVR);
    border: solid 3px  var(--blueAVR);
}

.consensus{
    font-size: medium;
    margin: 2%;
}

#closePopUp{
    cursor: pointer;
    font-size: xx-large;
    font-weight: 500;
    color: var(--blueAVR);
    position: absolute;
    top: 9px;
    right: 1vw;
}
@media (min-width: 600px){
    .whiteButtons{
        min-width: fit-content;
    }
}

@media (max-width: 600px) {
    #closePopUp{
        font-size:xx-large;
        top: -2px;
        right: 11px;
    }
}