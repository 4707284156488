.singleMessage{
    cursor:pointer;
    display:grid;
    grid-template-columns: 3% 15% auto 25%;
    /* grid-auto-columns: minmax(min-content, 3%) minmax(min-content, 13%) minmax(min-content, auto) minmax(min-content, 25%); */
    font-weight: 300;
    padding: 1em;
    gap: .5em;
}

.singleMessage .subjectMessage{
    padding: 0 0 .5em;
    margin: 0;
}

.singleMessage:hover{
    color:var(--blueAVR);
    font-weight:bold;
}
.listaNot a{
    cursor: pointer;
    text-decoration: none;
    color: unset;
}

.dateMessage{
    text-align: center;
    font-size: .8em;
}

.fromMessage{
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8em;
}

#usersIcon{
    cursor: help;
}

#usersIcon:hover + .tooltipUsers{
    opacity:1;
}

.tooltipUsers{
    background-color: rgb(245, 245, 245);
    padding: 0.5%;
    border-radius:5px;
    position:absolute;
    width: 5vw;
    text-align: center;
    left: 33vw;
    top: 32vh;
    opacity: 0;
    font-weight:400;
    font-size: small;
}

.clockIconMsgs{
    cursor: help;
    margin-left: 11vw;
    padding: .2em;
    box-sizing: content-box;
}

.clockIconMsgs:hover + .tooltipClockMsgs{
    opacity:1;
}

.tooltipClockMsgs{
    background-color: rgb(245, 245, 245);
    padding: 0.4%;
    border-radius:5px;
    position:absolute;
    width: 4vw;
    text-align: center;
    left: 80vw;
    top: 32vh;
    opacity: 0;
    font-weight:400;
    font-size: small;
}

#usersIconSent{
    cursor: help;
}

#usersIconSent:hover + .tooltipUsers{
    opacity:1;
}

.busta{
    text-align: center;
}

.headListaMessages{
    display: flex;
    font-weight: 500;
    margin-left: 3vw;
    text-align: center;
    width: 83%;
    justify-content: space-between;
}

.textShortform{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 .5em;
}
@media (min-width: 600px){

    .messageTypeCircle{
        margin-left:0.5vw; 
        width:0.5vw;
        height:0.5vw;
        border-radius:100%;
        display:inline-block;
    }
    .tooltipTypeMessage{
        opacity:0;
        display: inline-block;
        font-size:smaller;
        background-color:var(--blueAVR);
        color:white;
        margin: 0 1%;
        padding: .5em;
        position:absolute;
        top: 3vh;
        /* left: 11vw; */
    }
    .messageTypeCircle:hover + .tooltipTypeMessage{
        opacity:1;
    }
    .dateMessage{
        position:relative;
    }
    .singleMessage{
        margin-bottom: 2.5vh;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background:white;
    }
    .singleMessage:hover{
        border-radius: 2px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }


    .fadeIn {
        animation: fadeInAnimation 1s;
    }

    @keyframes fadeInAnimation {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}

@media (max-width: 600px) {
    .headListaMessages {
        display:none;
    }
    .singleMessage{
        box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 53%);
        border-radius: 2px;
        min-height: 11vh;
        margin-bottom: 2vh;
        position: relative;

        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(3, 1fr);
        align-items: center;
        text-align: left;
    }
    .singleMessage:hover{
        border-radius: 0px;
    }

    .busta{
        text-align: center;
        grid-area: 1 / 1 / 2 / 2;
        padding:0 2vw;
    }
    .singleMessage .subjectMessage{
        /* grid-column-start: 2; */
        grid-area: 2 / 1 / 3 / 8;

        font-size: larger;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
        margin-left:3vw;
        width: 97%;
    }
    .fromMessage {
        grid-area: 1 / 2 / 2 / 5;

    }
    .dateMessage {
        display: flex;
        align-items: center; 
        justify-content: space-evenly;
        padding:3%;
        font-size: .8em;

        grid-area: 1 / 5 / 2 / 8; 
    }

    #orologioIcon{
        margin:2% 0;
    }
    .textShortform{
        grid-area: 3 / 1 / 4 / 8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        margin-left:3vw;
    }
    .messageTypeCircle{
        display:none;
    }
    .tooltipTypeMessage{
        display:none;
    }
    
}

