.rowsHeadRight {
    display: grid;
    grid-template-rows: 50% 50%;
    justify-content: center;
}

.columnsHead{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom:2%;
}

h2{
    margin-right: 0%;
    color : var(--blueAVR);
    font-weight: 400;
}

.headNewNot h2{
    font-size: x-large;
}

button{
    margin: 1%;
} 


.infoModalTitle{
    margin: 2% 0;
    font-size: x-large;
}
#orderButtonsContainer{
    padding: 2vh;
}

#dataButton{
    width: 10vw;
}
#rateButton{
    width: 10vw;
}
#ordinaPer{
    display: inline;
    font-weight: 300;
}
#ricevutiButton{
    padding: 1% 3%;
}

#inviatiButton{
    padding: 1% 3%;
}
#archiviatiButton{
    padding: 1% 3%;
}
@media (min-width: 600px) {
    .titoloLogin{
        font-size: xx-large;
        margin-bottom: 3%;
    }
    .titoloRegister{
        font-size: xx-large;
        margin-bottom: 3%;

    }
    .headBacheca{
            margin: 4vh 0 2vh 3vw;
            grid-column: 1/3;
    }
    .headMessage{
        margin: 7vh 0 5vh 0;
    }

    .bachecaAvvisi{
        display:none;
    }

    .messagesTitle{        
        display:none;
    }
}
@media (max-width: 600px) {
    .columnsHead{
        display: block;
        grid-template-columns: 0% 0%;
        margin: 0 auto;
    }
    .bachecaAvvisi{
        width: 100vw;
        font-size: large;
        margin-top: 16vh;
        text-align: center;
    }
    #ordinaPer{
        display: block;
        font-size: small;
        font-weight: 200;
        width: 100vw;
        text-align: center;
        margin: 3vh auto 1vh auto;
    }
    #orderButtonsContainer{
        display: flex;
        width: 100vw;
        justify-content: space-around;
        padding:0;
    }
    #dataButton{
        width: 37vw;
        /* max-height: 6vh; */
        font-weight: 600;
        padding: 2% 2%;
    }
    
    #rateButton{
        width: 40vw;
        font-weight: 600;
        padding: 2% 2%;
    }

    #archiviatiButton{
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 5%;
    }

    .messagesTitle{
        font-weight: 400;
        padding-top: 2vh;
        margin-bottom: 8vh;
        text-align: center;
        width: 100vw;
        font-size: large;
        text-decoration: none;
    }

    #ricevutiButton{
        width: 34vw;
        font-weight: 600;
        padding: 2% 3%;
    }
    
    #inviatiButton{
        width: 30vw;
        font-weight: 600;
        padding: 2% 3%;
    }

    #containerRicevutiInviati{
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100vw;
        justify-items: center;
    }

   
}