.inputData{
    cursor: pointer;
}
.resultsContainer a {
    text-decoration: none;
    color: unset;
}


@media (min-width: 600px){
    .cerca{
        position: absolute;
        padding:0;
        top: 18vh;
        right: 13vw;
        width: 3vw;
        height: 3vw;
        border-radius: 5vw;
        display:flex;align-items: center;
        justify-content: center;
    }
    .cerca:active{
        border:none;
    }

    .filterContainer{
        background: var(--blueAVR);
        color: white;
        position: sticky;
        width: auto;
        z-index:1;
        top:0;
        /*
        background: rgb(0,119,188);
        background: linear-gradient(180deg, rgba(0,119,188,1) 55%, rgba(249,249,249,0) 100%);*/
        transition: all 1s;
        height: 50vh;
        padding: 3% 2%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1900' height='900' preserveAspectRatio='none' viewBox='0 0 1900 900'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1038%26quot%3b)' fill='none'%3e%3crect width='1900' height='900' x='0' y='0' fill='rgba(0%2c 119%2c 188%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c872.035C169.312%2c910.2%2c368.849%2c892.44%2c501.956%2c781.059C633.342%2c671.118%2c623.787%2c471.625%2c674.185%2c307.89C717.415%2c167.443%2c772.72%2c35.216%2c776.743%2c-111.679C781.402%2c-281.806%2c798.343%2c-467.529%2c699.207%2c-605.866C597.896%2c-747.239%2c422.6%2c-837.135%2c249.019%2c-848.08C85.75%2c-858.375%2c-48.274%2c-735.681%2c-194.444%2c-662.217C-313.417%2c-602.422%2c-427.75%2c-545.142%2c-528.03%2c-457.541C-642.499%2c-357.545%2c-806.43%2c-269.156%2c-817.893%2c-117.595C-829.44%2c35.084%2c-664.557%2c136.701%2c-582.764%2c266.139C-513.832%2c375.224%2c-464.381%2c490.398%2c-374.929%2c583.4C-263.335%2c699.424%2c-157.041%2c836.636%2c0%2c872.035' fill='%23006ead'%3e%3c/path%3e%3cpath d='M1900 1593.613C2025.589 1564.662 2076.742 1413.365 2186.44 1345.71 2299.371 1276.061 2495.743 1317.821 2546.167 1195.094 2597.2709999999997 1070.71 2423.9049999999997 960.403 2399.044 828.248 2375.87 705.063 2485.277 558.518 2407.867 459.931 2331.292 362.40700000000004 2174.837 394.078 2051.161 385.193 1950.905 377.991 1857.454 408.34 1757.056 413.177 1617.057 419.922 1460.4180000000001 339.48699999999997 1345.135 419.206 1231.194 497.997 1203.728 660.744 1198.837 799.188 1194.208 930.224 1265.054 1045.829 1318.774 1165.437 1372.853 1285.843 1407.66 1422.45 1513.93 1500.737 1622.7 1580.865 1768.355 1623.96 1900 1593.613' fill='%230081cb'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1038'%3e%3crect width='1900' height='900' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
        
    }

    .filterContainer--closed{
        background: var(--blueAVR);
        color: white;
        position: sticky;
        width: auto;
        z-index:1;
        cursor:pointer;
        top:0;
        transition: all 1s;
        height: 9vh;
        padding: 1% 2%;

    } 
    .filterContainer--closed:hover{
        text-decoration: underline;
    }

    .bodyFilterContainer{
        opacity: 1;
        visibility: visible;
        transition: all 1.2s ease-in;
       /*  display:block; */

    }
    .bodyFilterContainer--closed{
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
       /*  display:none; */
    } 

    .searchPageContainer label{
        display:inline;
    }

    .searchPageContainer input{
        height: 3vh;
        border-radius: 0.8em;
        margin: 0 auto;
        padding: 0 0.5vw;
    }
    #containerFiltriData{
        height: 6vh;
        display: inline-grid;
        grid-template-columns: 17.5vw 17vw;
        align-items: center;
        width: 35vw;
        padding: 1vh;
    }
    .bottoneCancellaData{
        border: none;
        border-radius: 7vh;
        width: 3vh;
        height: 3vh;
        margin-left: 1vw;
        font-weight: 900;
        cursor: pointer;
    }
    
    .cancellaFiltriIcon{
        margin-left: 1vw;
        cursor: pointer;
    }
    .backButtonSearch{
        width: 3vw;
        height: 3vw;
        border: none;
        cursor: pointer;
        font-size: large;
        background: white;
        border-radius: 100%;
        color: var(--blueAVR) ;
        transform: rotateY(3.142rad);
    }
    .backButtonSearch a{
        text-decoration: none !important;
        color: var(--blueAVR) !important;
    }
    .resultsContainer{
        padding: 2% 3% 8%;
        width: auto;
        height: 70vh;
        overflow-y: scroll;
    }

    .boxInputSearch{
        display: grid;
        grid-template-columns: 15vw 15vw 3vw;
        align-items: center;
        height: 6vh;
        width: 32vw;
        margin-right:6vw;
        padding: 1vh;
    }

    .cancellaFiltri{
        padding:2vh 1vh;
    }

    .headFilterContainer{
        margin-bottom: 2vh;
        font-size: larger;
        font-weight: 500;
        display: grid;
        grid-template-columns: 15% 50%;
        align-items: center;
        height: 8vh;
    }
    .searchPageContainer{
        background:#f7f7f7;
    }
    .headFilterContainer p{
        text-align: center;
    }
    .angleUp{
        position:absolute;
        right: 5vw;
        bottom: 3vh;
        font-size: xx-large;
        cursor:pointer;
    }
    .down{
        bottom: 3vh;
        transform: rotateZ(3.142rad);
    }

}

@media (max-width: 600px){
    .cerca{
        width: 11vw;
        height: 11vw;
        font-weight: 600;
        font-size: small;
        position: absolute;
        top: 15vh;
        right: 7vw;
        border-radius: 10vw;
    }
    .cerca:active{
        border:none;
    }
    .filterContainer{
        padding:4vw;
        background: var(--blueAVR);
        color: white;
        transition: all 1s;
        position: sticky;
        width: auto;
        z-index:1;
        top:0;  

        height: 100vh;
    }
    .filterContainer--closed{
        padding:4vw;
        background: var(--blueAVR);
        color: white;
        transition: all 1s;
        position: sticky;
        width: auto;
        z-index:1;
        cursor:pointer;
        top:0;

        height: 14vh;

    } 
    .bodyFilterContainer{
        opacity: 1;
        visibility: visible;
        transition: all 1.2s ease-in;
       /*  display:block; */
        text-align: center;
        margin-top:5vh;
    }

    .bodyFilterContainer--closed{
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
       /*  display:none; */
        text-align: center;
        margin-top:5vh;
    } 
    .angleUp{
        position:absolute;
        right: 5vw;
        bottom: 7vh;
        font-size: x-large;
        cursor:pointer;
    }
    .down{
        bottom: 2vh;
        transform: rotateZ(3.142rad);
    }

    .searchPageContainer input{
        height:4vh;
        border-radius: 0.8em;
        margin-top:2vh;
        padding: 0 3vw;
    }
    .bottoneCancellaData{
        border: none;
        border-radius: 7vh;
        width: 8vw;
        height: 8vw;
        margin-left: 5vw;
        font-weight: 900;
        cursor: pointer;
    }
    .cancellaFiltri{
        text-align: start;
        position:absolute;
        bottom: 7vh;

    }
    .cancellaFiltriIcon{
        margin-left: 4vw;
    }
    .searchPageContainer{
        width:100vw;
    }
    .resultsContainer{
        padding: 3%;
        margin-bottom: 5vh;
        font-size: small;
    }
    .backButtonSearch{
        width: 10vw;
        height: 10vw;
        border: none;
        cursor: pointer;
        font-size: large;
        border-radius: 100%;
        transform: rotateY(3.142rad);
        background: white;
        color: var(--blueAVR);
    }
    .backButtonSearch a{
        color:var(--blueAVR);
    }
    .headFilterContainer{
        display: grid;
        grid-template-columns: 22% 66%;
        align-items: center;
    }
    .headFilterContainer p{
        text-align: justify;
    }
}
