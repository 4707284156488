
.sidebar{
    background-color: white; 
/*     width: 18vw; */
    padding: 7% 3% 7% 7%;
}

.sideMenu{
    /* width: 95%; */
    margin-top:18%;
    margin-left:11%;
}

.sideMenu a{
    font-size:large;
    color: black;
    text-decoration: none;
    font-size:1rem;
}

.sideMenu a:hover{
    color: var(--blueAVR);
    font-weight: 700;
}

.sideMenu .active{
    color: var(--blueAVR);
    font-weight: 700;
}



.privacyDiv a{ 
    font-size: medium;
    font-weight: 400;
    color: black;
    text-decoration: none;
}
.privacyDiv a:hover{
    color: var(--blueAVR);
    font-weight: 700;
}

.sideMenuButtons{
    background-color: #f7f7f7;
    border: none;
    border-radius: 30px;
    color: var(--blueAVR);
    cursor: pointer;
    font-size: medium;
    font-family: inherit;
    font-weight: 600;
    padding: 3% 5%;
}

.sideMenuButtons:hover{
    background: var(--blueAVR);
    color: #ffffff;
}

@media (min-width: 600px){
    #topDivSidebar{
        position: fixed;
    }
    .sideMenu{
        position: fixed;
        margin-top: 25vh;
        margin-left: 3vw;
    }

    #topDivSidebar{
        font-size: x-large;
        display: flex;
        align-items: center;
        color: var(--blueAVR);
        /* font-weight: 800; */
        justify-content: center;
        width: 18%;
        flex-wrap: wrap;
    }
    #titoloSidebar{
        margin-top: 0vh;
        font-size: 2.5rem;
        font-weight: 200;
    }
    
    .bottoniSidebar{
        height: 16vh;
        position: fixed;
        bottom: 7vh;
        width: 18vw;
    }
    .privacyDiv{
        text-align: center;
        /* margin-top: 5vh; */
        width: 17vw;
        padding: 1vh;
        font-size: medium;
        border-top: solid 1px #c9c7c7;
        position: fixed;
        bottom: 1vh;
    }
    .sideMenu p{
        margin-bottom:3vh;
        white-space: nowrap;
    }

}
@media (max-width: 600px) {
    .sidebar{
        width: 90vw;
        position: fixed;
        top: 13vh;
        background-color: var(--blueAVR);
        padding: 0 5vw;
        z-index: 3;
        transition: all 0.8s;
        transform: translatex(-115vw); /* a -95 è CHIUSA */
        height: -webkit-fill-available;
        display: grid;
        grid-template-rows: 0% auto 15%;
    }
    .open{
        transform: translatex(0vw);
    }
    .sideMenu{
        margin: 5vh 0;
    }
    .sideMenu a{
        color: white;
        font-size:medium;
    }
    .sideMenu p{
        margin: 4vh 2vh;
    }

    .sideMenuButtons{
        font-size:small;
    }
    .bottoniSidebar{
        margin-top: 40vh;
        height: 20vh;
    }

    .privacyDiv {
        text-align: unset;
        font-size: xx-small;
        border-top: solid 1px white;
        padding: 3vh;
    }
    .privacyDiv a {
        font-size: small;
        color: white;
        text-decoration: none;
    }
}