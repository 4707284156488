.containerNewNot{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:100%;
}

.headNewNot {
    display: flex; 
    color: var(--blueAVR);
    width: 76vw;
}

.bodyNewNot{
    width:45vw;
    padding:0 3%;
    border-radius:15px;
    text-align: center;
}

.inputTopic{
    border: none;
    border-radius: 2px;
    min-height: 25px;
    width: 30vw;
    padding:1%;
    font-family: inherit;
    text-align:center;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

select{
    cursor: pointer;
    border: none;
    border-radius: 2px;
    min-height: 25px;
    width: 16vw;
    padding:1%;
    font-family: inherit;
    margin-bottom: 1%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background: white;
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

select:focus-visible{
    outline: none;
    border:none;
}

option:focus-visible{
    outline: none;
}

.inputTopic:focus-visible{
    outline: none;
    caret-color:var(--blueAVR);
}



.buttonWrap{
    display: flex;
    margin-top: 8%;
    margin-bottom:3%;
    justify-content: space-around;
}
.errorP{
    color:red;
    margin:1%;
}
#faClockIcon{
    margin-left: 1vw;
    margin-right:1vw;
}
@media (min-width: 600px) {
    #newNotTitle {
        margin: 5vh auto;
    }
    .containerNewNot textarea{
        
        border: none;
        border-radius: 2px;
        min-width: 30vw;
        min-height: 10vh;
        padding:2%;
        font-family: inherit;
        caret-color:var(--blueAVR);
        text-align: justify;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    
    .containerNewNot textarea:focus-visible{
        outline: none;
    }
    .bodyNewNot .errorP{
        margin-top:4vh;
    }
    .newNotButtonContainer{
        margin-top:3vh;
    }


}
@media (max-width: 600px) {
    .containerNewNot{
        width:100vw;
    }
    #newNotTitle{
        width: 100vw;
        font-size: large;
        text-decoration: none;
        margin-top: 4vh;
        text-align: center;
        margin-bottom:0vh;
    }
    .bodyNewNot{
        width:100vw;
    }
    .bodyNewNot input{
        margin-top:2vh;
        width:70vw;
    }
    .containerNewNot textarea{
        border: none;
        border-radius: 2px;
        padding:2%;
        font-family: inherit;
        caret-color:var(--blueAVR);
        text-align: justify;
        box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        background: white;
        /* transition: all 0.3s cubic-bezier(.25,.8,.25,1);  */
        margin-top:2vh;
        width:70vw;
        min-height:25vh; 
    }
    
    .containerNewNot textarea:focus-visible{
        outline: none;
    }

    .bodyNewNot h4{
        font-weight: 200;
    }

    #faClockIcon{
        margin-left: 6vw;
    }
    .errorP{
        margin-top:4vh;
        font-size: small;
    }
}
