
.blueButtons{
    cursor: pointer;
    padding: 0.5% 1%;
    font-family: inherit;
    font-size: medium;
    font-weight: 400;
    background:  var(--blueAVR);
    border: solid 3px  var(--blueAVR);
    border-radius: 25px;
    color:white;

    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.blueButtons:active{
    color:var(--blueAVR);
    background: #f7f7f7;
    border: solid 3px  #f7f7f7;
}

.buttonCont{
    align-items: center;
}
.rating{
    cursor: pointer;
    font-size: x-large;
    background:  none;
    border:none;
    color:var(--blueAVR);
    margin:0.5%;
}
.green{
    color:rgb(13, 165, 13);
}

.red{
    color:red;
} 

.ratingMessage{
    color: red;
    font-size: small;
    text-align: center;
    
}

.noticesButtons{
    background-color: #f7f7f7;
    border: none;
    border-radius:30px;
    color: var(--blueAVR);
    cursor: pointer;
    font-size: medium;
    font-family: inherit;
    font-weight: 600; 
    padding: 3% 5%;
}

.noticesButtons:hover{
    background: var(--blueAVR);
    color: #ffffff;
}

.confermaDelete{
    /* padding: 1.5% 2%; */
}
@media (min-width: 600px){
    .blueButtons{
        min-width: fit-content;
    }
}

@media (max-width: 600px){
    .ratingMessage {
        position:static;
        width: 100%;
    }
}

